import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCxqZaLOUx-cZhyWGCxYGgT4jr-bGuRwfQ",
    authDomain: "fibsevent.firebaseapp.com",
    databaseURL: "https://fibsevent-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "fibsevent",
    storageBucket: "fibsevent.appspot.com",
    messagingSenderId: "908874080175",
    appId: "1:908874080175:web:aa0bd7a24570ae6c99195a",
    measurementId: "G-ZYRMD8NBWX"
};

// function storage(){
//     const app = initializeApp(firebaseConfig)
//     return getStorage(app);
// }
const app = initializeApp(firebaseConfig)
const storage = getStorage(app);
export default storage;
