import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
  InputProps,
} from "@mui/material";
import moment from "moment-timezone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ClipLoader } from 'react-spinners';

import app from "../../utils/firebase";
import { ref, set } from "firebase/database";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Swal from "sweetalert2";
import YoutubeEmbed from "./youtubeEmbed";

// core components
import GalleryPageHeader from "components/Headers/GalleryPageHeader.js";
import Navbar from "components/Navbars/Navbar.js";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import "./style.scss";
import DtPicker from "react-calendar-datetime-picker";
import "react-calendar-datetime-picker/dist/index.css";

// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers/DateRangePicker';
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import Modal from 'react-modal';
// const MySwal = withReactContent(Swal)

// MySwal.fire({
//     title: <p>Hello World</p>,
//     didOpen: () => {
//         // `MySwal` is a subclass of `Swal` with all the same instance & static methods
//         MySwal.showLoading()
//     },
// }).then(() => {
//     return MySwal.fire(<p>Shorthand works too</p>)
// })

const style = {
  noBorder: {
    border: "none",
  },
  root: {
    borderColor: "orange",
  },
};

function Hotel() {
  const db = app();

  const [selectedDate, handleDateChange] = useState(new Date());

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const validation = Yup.object().shape({
    hotel_name: Yup.string().required("This is a required field"),
    room_type: Yup.string().required("This is a required field"),
    name: Yup.string().required("This is a required field"),
    contact_number: Yup.string().required("This is a required field"),
    email: Yup.string().email().required("This is a required field"),
    airport_transfer: Yup.string().required("This is a required field"),
  });

  const optionsForm = { resolver: yupResolver(validation) };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(optionsForm);

  const [dateCalendar, setDateCalendar] = useState(null);
  // const [value, setValue] = React.useState(0);

  let date = moment().tz("Asia/Singapore").format("ddd, MMM D YYYY");

  const [checkInvalue, setCheckInvalue] = useState(moment("9/13/2023"));
  const [checkOutvalue, setCheckOutvalue] = useState(moment("9/16/2023"));

  const options = [
    {
      label: "Westin Dubai Mina Seyahi",
      value: "AED 4‘500",
    },
    {
      label: "Le Meridien Mina Seyahi",
      value: "AED 3‘750",
    },
  ];

  const [hotelDetails, setHotelDetails] = useState({});

  const getHotelCalendar = (data) => {
    console.log(data);
    setHotelDetails(data);
  };

  // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //     event.preventDefault()
  // };

  const handleChange = (e) => {
    setHotelDetails({ value: e.target.value });
    e.preventDefault();
  };

  const showAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Alert successful",
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  const onSubmit = (data) => {
    const uid = new Date().getTime().toString();
    let date = moment().tz("Asia/Singapore").format("LLLL");

    set(ref(db, "hotels/" + uid), {
      ...data,
      id: uid,
      check_in: dayjs(checkInvalue).format("ddd, MMM D, YYYY"),
      check_out: dayjs(checkOutvalue).format("ddd, MMM D, YYYY"),
      created_at: date,
    });

    Swal.fire({
      title: "Reservation successful",
      text: "Please expect an email from us.",
      icon: "success",
      confirmButtonText: "OK",
    });

    reset();
  };

  const items = [
    {
      src: require("assets/img/gallery/day-one.jpeg"),
      caption: "Day 1 - Conference",
      relatedRange: [1, 34],
      path: '/day1'
    },
    {
      src: require("assets/img/gallery/day-two.jpeg"),
      caption: "Day 2 - Conference",
      relatedRange: [1, 30],
      path: '/day2'
    },
    {
      src: require("assets/img/gallery/day-three.jpeg"),
      caption: "Day 3 - Conference",
      relatedRange: [1, 30],
      path: '/day3'
    },
    {
      src: require("assets/img/gallery/cocktail.jpg"),
      caption: "Cocktail Party",
      relatedRange: [1, 23],
      path: '/cocktail'
    },
    {
      src: require("assets/img/gallery/sunset.jpeg"),
      caption: "Sunset Dhow Cruise Tour",
      relatedRange: [1, 38],
      path: '/sunset'
    },
  ];
  const generateImageURLs = (range,path) => {
    const [start, end] = range;
    const urls = [];
    for (let i = start; i <= end; i++) {
      urls.push(`${path}/${i}.JPG`);
    }
    return urls;
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [galleryCaption, setGalleryCaption] = useState('')

  const openModal = (range,path,caption) => {
    const relatedImages = generateImageURLs(range,path);
    setGalleryCaption(caption)
    setCurrentImages(relatedImages);
    setLoading(true);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImages([]);
    setLoading(false);
  };



  return (
    <>
      <Navbar />
      {/* <SubHeader title="HOTEL" image={require("assets/img/gallery/group-picture.jpg")} /> */}
      <GalleryPageHeader />
      <div className="wrapper position-relative">
        <div className="main my-5">
          <section className="section gb nopadtop">
            <div className="container">
              <div className="boxed boxedp4">
                <div className="row">
                  <div className="col-md-12 section-title text-center">
                    <h2 className="font-weight-bold mb-2"> FIBS Global Conference Post-Event 2023</h2>
                    <h3 className="fs-5 w-lg-60 mb-0 mx-auto ">
                              The Westin Dubai Mina Seyahi Beach Resort & Marina
                              </h3>
                              <h4 className="my-2"> SEPTEMBER 13 - 16, 2023 </h4>
                  </div>
                </div>

                <div className="col-md-12 mb-5">
                  <div className="my-1">
                
                    <div className="grid-container-video mt-5">
                      <YoutubeEmbed
                        embedId="s9u8UdlL-0g"
                        title={"FIBS Global Conference 2023"}
                      />
                      <YoutubeEmbed
                        embedId="dtiZunHJY3Y"
                        title={"FIBS Global Conference Sunset Cruise 2023"}
                      />
                      <YoutubeEmbed
                        embedId="4uEob5ZV2Hk"
                        title={"FIBS Global Conference 2023 Cocktail Party"}
                      />
                    </div>

                    <div className="grid-container">
                      {items.map((item, index) => (
                        <div
                          key={index}
                          className="grid-item bg-white shadow px-2 pt-2 pb-3 gallery-image"
                        >
                          <img 
                            src={item.src} 
                            alt={`Image ${index + 1}`} 
                            onClick={() => openModal(item.relatedRange, item.path, item.caption)}
                            onLoad={() => setLoading(false)}
                            style={{ cursor: 'pointer', margin: '10px', display: loading ? 'none' : 'block' }}
                          />
                          <div className="caption1">
                            <p> {item.caption} </p>
                          </div>
                          {loading && (
                            <div className="loader">
                              <ClipLoader color={'#123abc'} loading={loading} size={150} />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
           <Dialog 
            maxWidth="lg"
            open={modalIsOpen} onClose={closeModal}>
            <DialogTitle className="bg-danger text-white">
              FIBS Gallery - {galleryCaption}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
            <CloseIcon />
          </IconButton>
            <DialogContent>
              <div className="grid-container">
                {currentImages.map((img, index) => (
                  <div className="grid-item bg-white shadow px-2 pt-2 pb-3 gallery-image">
                    <img key={index} src={require(`assets/img/gallery${img}`)} onLoad={() => setLoading(false)} alt="Related" style={{ margin: '10px' }} />
                    {loading && (
                      <div className="loader">
                          <ClipLoader color={'#123abc'} loading={loading} size={100} />
                      </div>
                    )}
                 </div>
                ))}
              </div>
            </DialogContent>
           </Dialog>
        </div>
         <DarkFooter />
      </div>
    </>
  );
}

export default Hotel;