import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import 'firebase/storage'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCxqZaLOUx-cZhyWGCxYGgT4jr-bGuRwfQ",
    authDomain: "fibsevent.firebaseapp.com",
    databaseURL: "https://fibsevent-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "fibsevent",
    storageBucket: "fibsevent.appspot.com",
    messagingSenderId: "908874080175",
    appId: "1:908874080175:web:aa0bd7a24570ae6c99195a",
    measurementId: "G-ZYRMD8NBWX"
};

function Firebase() {
  
    const app = initializeApp(firebaseConfig)
    return getDatabase(app)
}

export default Firebase;

// import { initializeApp } from 'firebase/app';
// import 'firebase/database';
// // import { initializeApp } from "firebase/app";

// let firebaseConfig = {
//     apiKey: "AIzaSyCxqZaLOUx-cZhyWGCxYGgT4jr-bGuRwfQ",
//     authDomain: "fibsevent.firebaseapp.com",
//     databaseURL: "https://fibsevent-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "fibsevent",
//     storageBucket: "fibsevent.appspot.com",
//     messagingSenderId: "908874080175",
//     appId: "1:908874080175:web:aa0bd7a24570ae6c99195a",
//     measurementId: "G-ZYRMD8NBWX"
// };

// // const app = initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);

// export default app;

// import { getDatabase } from "firebase/database";
// import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//     apiKey: "AIzaSyCxqZaLOUx-cZhyWGCxYGgT4jr-bGuRwfQ",
//     authDomain: "fibsevent.firebaseapp.com",
//     databaseURL: "https://fibsevent-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "fibsevent",
//     storageBucket: "fibsevent.appspot.com",
//     messagingSenderId: "908874080175",
//     appId: "1:908874080175:web:aa0bd7a24570ae6c99195a",
//     measurementId: "G-ZYRMD8NBWX"
//   };


//   const app = initializeApp(firebaseConfig);
//   export const db = getDatabase(app);   

// import app from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';
// import 'firebase/storage';

// const config = {
//     apiKey: "AIzaSyCxqZaLOUx-cZhyWGCxYGgT4jr-bGuRwfQ",
//     authDomain: "fibsevent.firebaseapp.com",
//     databaseURL: "https://fibsevent-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "fibsevent",
//     storageBucket: "fibsevent.appspot.com",
//     messagingSenderId: "908874080175",
//     appId: "1:908874080175:web:aa0bd7a24570ae6c99195a",
//     measurementId: "G-ZYRMD8NBWX"
//   };

// class Firebase {
//     constructor() {
//         app.initializeApp(config);

//         /* Helper */

//         // this.serverValue = app.database.ServerValue;
//         // this.emailAuthProvider = app.auth.EmailAuthProvider;
//         // this.auth = app.auth();
//         this.db = app.database();
//         this.storage = app.storage();

//         // this.googleProvider = new app.auth.GoogleAuthProvider();
//     }

// }


// export default Firebase;


// import firebase from 'firebase';
  
// const firebaseConfig = {
//     apiKey: "AIzaSyCxqZaLOUx-cZhyWGCxYGgT4jr-bGuRwfQ",
//     authDomain: "fibsevent.firebaseapp.com",
//     databaseURL: "https://fibsevent-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "fibsevent",
//     storageBucket: "fibsevent.appspot.com",
//     messagingSenderId: "908874080175",
//     appId: "1:908874080175:web:aa0bd7a24570ae6c99195a",
//     measurementId: "G-ZYRMD8NBWX"
// };
    
// firebase.initializeApp(firebaseConfig);
// var database = firebase.database();
  
// export default database;