import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue} from "firebase/database";
import app from "../../utils/firebase";
import CsvDownloadButton from 'react-json-to-csv'
import exportFromJSON from 'export-from-json'
import { Link } from 'react-router-dom';
import './style.scss';


function Lodge(props){
    const db = app();

    const [hotelAttendees,setHotelAttendees] = useState([]);
    const [isLogin, setIsLogin] = useState(false)
    const [filterByYear, setFilterByYear] = useState([])
    const [year, setYear] = useState('2024');

    const getYearFromDate = (dateString) => {
        const date = new Date(dateString);
        return date.getFullYear();
      };


    useEffect(() => {
        var storedName = localStorage.getItem('name');
        var storedPw = localStorage.getItem('pw');

        if (storedName === "customerservice@fibsglobalconference.com" && storedPw === "fibs2023@!%"){
            const starCountRef = ref(db, 'hotels');
            onValue(starCountRef, (snapshot) => {
                let records = [];
    
                snapshot.forEach(element => {
                    // var data = element.data();
                    let keyName = element.key;
                    let data = element.val()
                    records.push({"key": keyName, "data": data})
                });
                setHotelAttendees(records)
                // console.log('querySnapshot',records)
    
            });
        }else{
            props.history.push('/v2/fibs/login')
        }


    },[])

    useEffect(() => {
      
        const filtered = hotelAttendees.filter((item) => getYearFromDate(item.data.created_at) === 2024 || getYearFromDate(item.data.created_at) === 2025);
        setFilterByYear(filtered)
   
    }, [year, hotelAttendees]);
  

    return (
        <div className="container-fluid">
            <h2 className="mt-3">Fibs Hotel Reservation of Attendees</h2>

            <Link to="/v2/fibs/list">
                <button className="btn btn-danger">Go to Fibs List of Attendees</button>
            </Link>
            <table className="table table-striped table-bordered d-block" style={{height:"700px",overflow:"auto"}}>
            <thead>
                <tr>
                    <th style={{ width: '100px'}}>#</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Hotel Name</th>
                    <th>Hotel Membership</th>
                    <th>Room Type</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Contact Number</th>
                    <th>Flight Details</th>
                    <th>Airport Transfer</th>
                    <th>Request</th>
                    <th>Created At</th>
                </tr>
            </thead>
            <tbody>
                {filterByYear.map((row,index) => {
                    return(
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row.data.id}</td>
                            <td>{row.data.name}</td>
                            <td>{row.data.email}</td>
                            <td>{row.data.hotel_name}</td>
                            <td>{row.data.hotel_membership}</td>
                            <td>{row.data.room_type}</td>
                            <td>{row.data.check_in}</td>
                            <td>{row.data.check_out}</td>
                            <td>{row.data.contact_number}</td>
                            <td>{row.data.flight_details}</td>
                            <td>{(row.data.airport_transfer?"true":"false")}</td>
                            <td>{row.data.request}</td>
                            <td>{row.data.created_at}</td>
                        </tr>
                    )
                })}


            </tbody>
            </table>
        </div>
    )
}


export default Lodge;