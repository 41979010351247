import React from "react";

const YoutubeEmbed = ({ embedId, title }) => (
    <div className="bg-white shadow px-2 pt-2 pb-3 mb-3">
        <div className="video-responsive">
            <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
            />
        </div>
    </div>
);

export default YoutubeEmbed;