import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';

// core components
import Navbar from "components/Navbars/Navbar.js";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";
import events from './agenda.json'
import './style.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {/* <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box> */}
                    <div className="index">
                        {children}
                    </div>
                </>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function Agenda() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    // const [basicActive, setBasicActive] = useState('tab1');

    // const handleBasicClick = (value) => {
    //     if (value === basicActive) {
    //         return;
    //     }

    //     setBasicActive(value);
    // };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        event.preventDefault()
    };


    return (
        <>
            <Navbar />
            <SubHeader title="CONFERENCE AGENDA" image={require("assets/img/danangbeach.png")} />
            <div className="wrapper position-relative mb-5">
                <div className="container main mt-5 px-lg-5">
                    <h4>
                    The FIBS Global Conference 2025 will be held in Da Nang, Vietnam. The 3.5-day event will consist of keynote speeches, network updates, Face-to-Face
                     Meetings, cocktail receptions, and after-conference evening events to give all delegates an unforgettable networking experience.
                    </h4>

                    <div className='container' style={{ marginBottom: '200px' }}>
                        <div className="col-12 text-left pt-3 d-none d-md-block desktop-table" >
                            <div className="bg-white shadow px-2 pt-2 pb-3">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <div className="table-title">
                                            <h3 className="title">FIBS Global Conference 18-21 February</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <h5>Tuesday, 18 February 2025</h5>
                                </div>
                                <table className="table table-bordered table-striped light-dark agenda-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Event</th>
                                            {/* <th scope="col">Venue</th> */}
                                            <th scope="col">Dress Code</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>06:45</td>
                                            <td><span className="font-weight-bold">Golf </span>
                                            <br />Meet at hotel lobby for transport to golf course</td>
                                            {/* <td></td> */}
                                            <td>Golf Attire</td>

                                        </tr>
                                        <tr>
                                            <td>08:00 - 13:00</td>
                                            <td><span className="font-weight-bold">FIBS 18-Hole Golf Game </span></td>
                                            {/* <td></td> */}
                                            <td>Golf Attire</td>

                                        </tr>
                                        <tr>
                                            <td>15:00 - onwards</td>
                                            <td><span className="font-weight-bold">Onsite Registration </span>
                                            <br />Pick up conference badges and other conference items</td>
                                            {/* <td></td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>19:00  – 22:00 </td>
                                            <td className="font-weight-bold">FIBS Spring Welcome Cocktail</td>
                                            {/* <td></td> */}
                                            <td>Beach Wear</td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-center mt-5'>
                                    <h5>Wednesday, 19 February 2025</h5>
                                </div>
                                <table className="table table-bordered table-striped light-dark agenda-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Event</th>
                                            {/* <th scope="col">Venue</th> */}
                                            <th scope="col">Dress Code</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>09:00  – 09:30 </td>
                                            <td className="font-weight-bold">Opening and Welcome</td>
                                            {/* <td>Conference Hall</td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>09:30 – 10:15 </td>
                                            <td>Speech</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>10:15  – 11:05 </td>
                                            <td>Coffee Break</td>
                                            {/* <td></td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>11:05  – 11:50 </td>
                                            <td>Speech</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>11:50  – 12:20 </td>
                                            <td>Group Photo</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>12:20  – 14:00 </td>
                                            <td>Lunch</td>
                                            {/* <td>Mina’s Kitchen</td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>14:00  – 16:00 </td>
                                            <td>Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>16:45 pm</td>
                                            <td>Meet at lobby for transport to Hoi An</td>
                                            <td>FIBS Conference Polo T-shirt</td>

                                        </tr>
                                        <tr>
                                            <td>17:30 -- onwards</td>
                                            {/* <td><span className="font-weight-bold">Cocktail and Dinner</span> <br/> Meet at hotel lobby for transport to Hoi An (UNESCO World Heritage Site)</td> */}
                                            <td>Dinner at Hoi An and visit to Hoi An Market</td>
                                            <td>FIBS Conference Polo T-Shirt</td>

                                        </tr>
                                        <tr>
                                            <td>22:00</td>
                                            <td>Transport back to hotel</td>
                                            <td>FIBS Conference Polo T-Shirt</td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-center mt-5'>
                                    <h5>Thursday, 20 February 2025</h5>
                                </div>
                                <table className="table table-bordered table-striped light-dark agenda-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Event</th>
                                            {/* <th scope="col">Venue</th> */}
                                            <th scope="col">Dress Code</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>10:00  – 11:00 </td>
                                            <td className="font-weight-bold">Special Guest Speaker </td>
                                            {/* <td>Conference Hall</td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>11:00 – 11:20</td>
                                            <td>Coffee Break</td>
                                            {/* <td></td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>11:20 – 12:20</td>
                                            <td>Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>12:20 – 14:00</td>
                                            <td>Lunch</td>
                                            {/* <td>Mina’s Kitchen</td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>14:00  – 15:40 </td>
                                            <td>Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>15:40  – 16:00 </td>
                                            <td>Coffee Break</td>
                                            {/* <td></td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>16:00 – 18:00 </td>
                                            <td>Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>18:30</td>
                                            <td>Meet at hotel lobby <br/> Transport to FIBS *Above the Ceiling" dinner
                                            </td>
                                            {/* <td></td> */}
                                            <td>Casual</td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-center mt-5'>
                                    <h5>Friday, 21 February 2025</h5>
                                </div>
                                <table className="table table-bordered table-striped light-dark agenda-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Event</th>
                                            {/* <th scope="col">Venue</th> */}
                                            <th scope="col">Dress Code</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>10:00 – 11:00 </td>
                                            <td>Face-to-Face Meetings</td>
                                            {/* <td>Conference Hall</td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>11:00  – 11:20 </td>
                                            <td>Coffee Break</td>
                                            {/* <td></td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>11:20  – 12:20 </td>
                                            <td>Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>12:20  – 14:00 </td>
                                            <td>Lunch</td>
                                            {/* <td></td> */}
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>14:00  – 15:20 </td>
                                            <td>Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>
                                        </tr>
                                        <tr>
                                            <td>15:20 - onwards</td>
                                            <td>Conference Close / Happy Hour</td>
                                            {/* <td></td> */}
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='container container-padding d-md-none'>
                            <div class="col-12 force-text-black px-1 pb-2">
                                <div class="card">
                                    <div class="card-body px-2 py-3">


                                        {/* {
                                            events.map((event) => (
                                                <>
                                                    
                                                    <table width="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td valign="top" width="30%">Time</td>
                                                                <td valign="top">{event.from} - {event.to}</td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="top">Event</td>
                                                                <td valign="top"><div dangerouslySetInnerHTML={{ __html: event.event }}></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="top">Venue</td>
                                                                <td valign="top">{event.vanue}</td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="top">Dress Code</td>
                                                                <td valign="top">{event.dress}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            ))
                                        } */}
                                        <div className="table-title">
                                            <h3 className="title text-center">FIBS Global Conference 18-21 February</h3>
                                        </div>
                                        <div className='text-center'>
                                            <h5>Tuesday, 18 February 2025</h5>
                                        </div>
                                        <table width="100%">
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">06:45</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top"><strong>Golf</strong><br />Meet at hotel lobby for transport to golf course</td>
                                                </tr>
 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">--</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Golf Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">08:00 - 13:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top"><strong>FIBS 18-Hole Golf Game</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Golf Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">15:00 - onwards</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top"><strong>Onsite Registration</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">--</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">19:00 – 22:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top"><strong>FIBS Spring Welcome Cocktail</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Beach Wear</td>
                                                </tr>
                                            </tbody>
                                        </table>


                                        <div className='text-center mt-5'>
                                            <h5>Wednesday, 19 February 2025</h5>
                                        </div>
                                        <table width="100%">
                                        <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">09:00 – 09:30</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top"><strong>Opening and Welcome</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">09:30 – 10:15</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Speech</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>

                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">10:15 – 11:05</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Coffee Break</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">11:05 – 11:50</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Speech</td>
                                                </tr>
 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">--</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">11:50 – 12:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Group Photo</td>
                                                </tr>
 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">--</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">12:20 – 14:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Lunch</td>
                                                </tr>
                                                {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">Mina’s Kitchen</td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">14:00 – 16:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Face-to-Face Meetings</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">16:45 pm</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Meet at lobby for transport to Hoi An</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">FIBS Conference Polo T-shirt</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">17:30 – onwards</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Dinner at Hoi An and visit to Hoi An Market</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">FIBS Conference Polo T-Shirt</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">22:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Transport back to hotel</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">FIBS Conference Polo T-Shirt</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className='text-center mt-5'>
                                        <h5>Thursday, 20 February 2025</h5>
                                        </div>
                                        <table width="100%">
                                        <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">10:00 – 11:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top"><strong>Special Guest Speaker</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">11:00 – 11:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Coffee Break</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">11:20 – 12:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Face-to-Face Meetings</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">12:20 – 14:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Lunch</td>
                                                </tr>
                                                {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">Mina’s Kitchen</td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">14:00 – 15:40</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Face-to-Face Meetings</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>

                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">15:40 – 16:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Coffee Break</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">16:00 – 18:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Face-to-Face Meetings</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">18:30</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Meet at hotel lobby
                                                    Transport to FIBS *Above the Ceiling" dinner</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Casual</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className='text-center mt-5'>
                                            <h5>Friday, 21 February 2025</h5>
                                        </div>
                                        <table width="100%">
                                        <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">10:00 – 11:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Face-to-Face Meetings</td>
                                                </tr>
                                               {/*   <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">Conference Hall</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">11:00 – 11:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Coffee Break</td>
                                                </tr>
                                               {/*   <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">Conference Hall</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">11:20 – 12:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Face-to-Face Meetings</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">12:20 – 14:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Lunch</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">14:00 – 15:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Face-to-Face Meetings</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>

                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top">15:20 - onwards</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top">Conference Close / Happy Hour</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Day 1" {...a11yProps(0)} />
                                <Tab label="Day 2" {...a11yProps(1)} />
                                <Tab label="Day 3" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                        </TabPanel>
                    </Box> */}

                </div>
                <PanelCountdown date="02/18/2025" />
                {/* <DarkFooter /> */}
            </div>
        </>
    );
}

export default Agenda;
