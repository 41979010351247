import React, { useState,Fragment } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import app from '../../utils/firebase';
import { ref, set } from "firebase/database"
import moment from 'moment-timezone'
// core components
import Navbar from "components/Navbars/Navbar.js";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";
import './style.scss';
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";

function ContactUs() {

    const db = app();

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm();

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        event.preventDefault()
    };

    const [name, setName] = useState();
    const [company, setCompany] = useState();
    const [email, setEmail] = useState();
    const [subject, setSubject] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);


     const onSubmit = async (data) => {
        setLoading(true)
        const uid = new Date().getTime().toString();
        let date = moment().tz('Asia/Singapore').format('LLLL');


        set(ref(db, 'contacts/' + uid), {
            ...data,
            id: uid,
            created_at: date
        })

        const contactUs1 = { 
            name: data.name,
            sendto: 'customerservice@fibsglobalconference.com',
            // sendto: 'flytechph@gmail.com',
            subject: `Inquire by ${data.company} - ${data.email}`,
            message: data.message 
        };

        var contactUs = {
            service_id: 'service_razxbzp',
            template_id: 'template_8t4xswr',
            user_id: 'FiLUegZsUcqSyl78X',
            accessToken: 'MvJXqbl4RPnK4i0_3-CAp',
            template_params: {
                'to_name': "FIBS Customer Service",
                'from_name': data.name,
                'message': data.message ,
                'reply_to': data.email,
                'from_company': data.company,
                'from_subject': data.subject
            }
        };

        await fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            body: JSON.stringify(contactUs),
            headers: {
                'Content-Type': 'application/json',
            },
          })        
          .then((response) => {
            console.log("Success:", response.status);
            setLoading(false)
            Swal.fire({
                title: "Success",
                text: "Message sent",
                icon: "success",
                confirmButtonText: "OK",
            });
        })
        .catch((error) => {
            console.error("Error:", error);
        });
         
          const autoMessage = `<div> <p>Good day ${data.name}!</p><p> Thank you for getting in touch with us.</p> <p> This confirms receipt of your email.</p> <p> Thank you!</p>  <p>FIBS Admin</p></div>`

          const autoReplyContactUs = {
              name: data.name,
              sendto: data.email,
              subject: `Automatic reply: Welcome to the FIBS Family!`,
              message: autoMessage,
          }

          reset();

    };

    return (
        <>
         <Navbar />

         
          <section className="position-relative bg-header-grey bg-opacity-10">
              <svg className="position-absolute start-0 bottom-0 text-white flip-y" width="100%" height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 152" fill="none" preserveAspectRatio="none">
                  <path d="M126.597 138.74C99.8867 127.36 76.6479 109.164 59.2161 85.9798L0 3.05176e-05L1440 0C1440 0 1419.98 25.8404 1380.15 32.9584L211.382 150.811C182.549 154.283 153.308 150.12 126.597 138.74Z" fill="currentColor"></path>
              </svg>
              <div className="container position-relative pt-12 pb-9">
                  <div className="row align-items-center pb-8 pt-lg-9 my-5">
                      <div className="col-md-10 col-lg-8 text-white">
                          <h1 className=" mb-3">
                              Get in touch with us
                          </h1>
                          <p className="mb-0 lead pe-lg-8">Do you have questions? <br />
                              Our Customer Service Team is always ready to help!</p>
                      </div>
                  </div>
              </div>
          </section>
          {
            loading ? 
            <div className='text-center'>
                <div>
                    <img
                        alt="sending ..."
                        className="n-logo"
                        style={{width:'150px'}}
                        src={require("assets/img/sending1.gif")}
                    ></img>
                </div>
                <div>

                <img
                    alt="sending ..."
                    className="n-logo"
                    style={{width:'100px'}}
                    src={require("assets/img/loading.gif")}
                ></img>
                </div>
            </div>
          : 
          <>
          <div className="wrapper" style={{marginBottom:'100px'}}>


              <section className="position-relative">
                  <div className="container py-9 py-lg-11 my-5">
                      <div className="row">
                          <div className="col-md-8 col-lg-7 mb-7 mb-md-0 me-auto">
                              <div className="position-relative">
                                  <h1>
                                      Contact form
                                  </h1>
                                  <p className="mb-3 w-lg-75">
                                      Kindly fill up the contact form and our customer service team will get back to you.
                                  </p>
                                  <div className="width-7x pt-1 bg-danger mb-5"></div>

                                  <form onSubmit={handleSubmit(onSubmit)} className="needs-validation mb-5 mb-lg-7" >
                                      <div className="row">
                                          <div className="col-sm-6 mb-3">
                                              <label className="form-label" htmlFor="name">Your name</label>
                                              <input type="text" name="name" className="form-control py-2" id="name" placeholder="John Doe" {...register("name", { required: true })} required="" />
                                              {errors.name && <><p className='my-2 mt-2 h4'><small className='text-danger '>This field is required</small></p></>}
                                          </div>
                                          <div className="col-sm-6 mb-3">
                                              <label className="form-label" htmlFor="email">Your email address</label>
                                              <input type="email" className="form-control py-2" name="email" id="email" placeholder="john@gmail.com" {...register("email", { required: true })} aria-label="jackwayley@gmail.com" required="" />
                                              {errors.email &&
                                                 <><p className='my-2 mt-2 h4'><small className='text-danger '>
                                                      Please enter a valid email address
                                                      </small></p></>
                                              }
                                          </div>

                                          <div className="col-sm-6 mb-3">
                                              <label className="form-label" htmlFor="subject">Company</label>
                                              <input type="text" className="form-control py-2" name="company" id="company" required="" {...register("company", { required: true })} />
                                              {errors.company && <><p className='my-2 mt-2 h4'><small className='text-danger '>This field is required</small></p></>}
                                          </div>

                                          <div className="col-sm-6 mb-3">
                                              <label className="form-label" htmlFor="subject">Subject</label>
                                              <input type="text" className="form-control py-2" name="subject" id="subject" required="" {...register("subject", { required: true })} />
                                              {errors.subject && <><p className='my-2 mt-2 h4'><small className='text-danger '>This field is required</small></p></>}
                                          </div>
                                      </div>

                                      <div className="mb-3">
                                          <label htmlFor="message" className="form-label">Message</label>
                                          <textarea className="form-control contact-us-textarea" name="message" placeholder="Hi there...." required=""  {...register("message", { required: true })}></textarea>
                                          {errors.message && <><p className='my-2 mt-2 h4'><small className='text-danger '>This field is required</small></p></>}
                                      </div>

                                      <div className="d-md-flex justify-content-between align-items-center">
                                          <p className="small mb-4 text-muted mb-md-0">We'll get back to you ASAP.</p>
                                          <input type="submit" name="submit" value="Submit message" id="sendBtn" className="btn btn-lg btn-danger" />
                                      </div>
                                  </form>
                                  {/* <div className="px-4 py-7 mb-5 px-lg-5 py-lg-7 border border-2 rounded-3 position-relative aos-init aos-animate" data-aos="fade-up">
                                      <div className="position-relative py-5">

                                          <h3 className="mb-4">Need customer support?</h3>
                                          <p className="w-lg-90 mb-5 lead">
                                              If you are already a customer with us, we will be happy to help you in our
                                              Customer Support.
                                          </p>
                                          <div className="width-6x pt-1 bg-danger mb-5"></div>
                                          <a href="#" className="btn btn-outline-primary btn-rise">
                                              <div className="btn-rise-bg bg-danger"></div>
                                              <div className="btn-rise-text">
                                                  Customer support
                                              </div>
                                          </a>
                                      </div>
                                  </div> */}
                              </div>
                          </div>
                          <div className="col-md-4">
                              <div data-aos="fade-up" className="aos-init aos-animate">
                                  <h4 className="mb-0">Offices</h4>
                                  <div className="border-top border-2 border-secondary mb-4 mt-2 aos-init aos-animate" data-aos="fade-up"></div>
                              </div>
                              <div data-aos="fade-up" className="aos-init aos-animate">
                                  {/* <h5>USA</h5> */}
                                  <div className="position-relative">
                                      <p>Website: <a rel="noopener" href="#!">https://fibsglobalconference.com/</a><br />Email: <a rel="noopener" href="#!">customerservice@fibsglobalconference.com</a>
                                      </p>
                                  </div>
                              </div>
                              <div className="border-top border-2 border-secondary my-4 my-lg-5 aos-init aos-animate" data-aos="fade-up"></div>

                          </div>
                      </div>
                  </div>
              </section>
           
            </div>
             </>
             }
          <PanelCountdown date="02/18/2025" />
        </>
    );
}

export default ContactUs;
