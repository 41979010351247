import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import { Link } from "react-router-dom";
// core components
import Navbar from "components/Navbars/Navbar.js";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";


function MeetingCost() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        event.preventDefault()
    };


    return (
        <>
            <Navbar />
            <SubHeader title="MEETING COST" image={require("assets/img/nightmarket.jpg")} />
            <div className="wrapper position-relative">
                <div className="container main my-5 px-5">
                    <section className="section gb nopadtop">
                        <div className="container">
                            <div className="boxed boxedp4">
                                <div className="row">
                                    <div className="col-md-12 section-title">
                                        <h2 className="text-center font-weight-bold">OUR EVENT RATES</h2>
                                    </div>
                                </div>
                                <div className='row d-flex flex-row justify-content-center mt-5'>
                                    <div className="col-lg-4 col-md-6 mb-5">
                                            <div className="bg-white shadow sponsor-box">
                                                <div className="text-center p-4">
                                                    <h1 className="mb-0"><small className="align-top text-muted font-weight-medium"
                                                        style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>1,200</h1>
                                                </div>
                                                <div className="bg-danger text-white text-center p-4">
                                                    <h3 className="m-0">CONFERENCE COST</h3>
                                                </div>
                                                <div className="d-flex flex-column px-3 py-4" style={{ maxHeight: "400px", minHeight: "400px" }}>
                                                    <p>Conference Pass Includes:</p>
                                                    <ul>
                                                    <li>Cocktail Reception</li>
                                                    <li>AM and PM coffee breaks with pastries </li>
                                                    <li>Daily lunch during conference </li>
                                                    <li>Gala Dinner</li>
                                                    <li>Conference materials</li>
                                                    <li>Conference T-Shirt</li>
                                                    <li>Access to Face-to-Face meeting scheduler</li>
                                                    </ul>


                                                </div>
                                                <div className='row justify-content-center pb-4'>
                                                    {/* <a href="/registration" className="btn btn-outline-danger py-2 px-4 my-2">Register Now</a> */}
                                                    <Link  className="btn btn-outline-danger py-2 px-4 my-2" to="/registration">
                                                    Register Now
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 mb-5">
                                            <div className="bg-white shadow sponsor-box">
                                                <div className="text-center p-4">
                                                    <h1 className="mb-0"><small className="align-top text-muted font-weight-medium"
                                                        style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>$1,750</h1>
                                                </div>
                                                <div className="bg-danger text-white text-center p-4">
                                                    <h3 className="m-0">VIP TABLE</h3>
                                                </div>
                                                <div className="d-flex flex-column px-3 py-4" style={{ maxHeight: "400px", minHeight: "400px" }}>
                                                    <p>VIP Table Benefits:</p>
                                                    <ul>
                                                        <li>Dedicated VIP Table (all one 2 one meetings at your dedicated table)</li>
                                                        <li>Near high foot traffic</li>
                                                        <li>1 Free conference pass</li>
                                                    </ul>
                                                     <ul>
                                                        <li>Cocktail Reception</li>
                                                        <li>AM and PM coffee breaks with pastries </li>
                                                        <li>Daily lunch during conference </li>
                                                        <li>Gala Dinner</li>
                                                        <li>Conference materials</li>
                                                        <li>Conference T-Shirt</li>
                                                        <li>Access to Face-to-Face meeting scheduler</li>
                                                    </ul>
                                                </div>
                                                <div className='row justify-content-center pb-4'>
                                                    <a href="mailto:customerservice@zepoga.com" className="btn btn-outline-danger py-2 px-4 my-2">Email Us Now</a>
                                                </div>

                                            </div>
                                        </div>

                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                <PanelCountdown date="02/18/2025" />
                {/* <DarkFooter /> */}
            </div>
        </>
    );
}

export default MeetingCost;
