import React, { useState, useEffect } from "react";
import {Button} from "reactstrap";
import moment from 'moment-timezone'
import CookieConsent from "react-cookie-consent";
import './style.scss';

function PanelCountdown(props) {

    let date = moment(props.date).tz('Asia/Singapore')
    

    const [count, setCount] = useState({
        days: 0,
        hours: '00',
        minutes: '00',
        seconds: '00',
        timeUp: false
    })

    useEffect(() => {
        setInterval(() => {
            // let eventDate = new Date(date);
            let currentDate =  moment().tz("Asia/Singapore");
            let difference = date - currentDate

            if (difference < 1) {
                setCount({ timeUp: true });
            } else {
                let days = Math.floor(difference / (1000 * 60 * 60 * 24));
                let hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                let minutes = Math.floor((difference / (1000 * 60)) % 60);
                // let seconds = Math.floor((difference / (1000)) % 60);
                setCount({
                    hours: hours > 9 ? hours : `0${hours}`,
                    minutes: minutes > 9 ? minutes : `0${minutes}`,
                    // seconds: seconds > 9 ? seconds : `0${seconds}`,
                    days
                });
            }

        }, 1000);
    },[]);


    const { days, hours, minutes, timeUp } = count;
    const dayString = days > 1 ? 'days' : 'day';


    return (
        <>
            {timeUp ??
                // <p>Event in progress</p>
                // :
        //   <p>{ `${days} ${dayString} ${hours} hours ${minutes}  minutes ${seconds} seconds` }</p>

             <div className="panel-countdown">
                <div className="container text-center">
                   <div className="panel-countdown-inner">
                        <span className="days"> <span>{`${days}`}</span> {`${dayString}`}</span>
                        <span className="hours"> <span>{`${hours}`}</span> hours </span>
                        <span className="minutes"> <span>{`${minutes}`}</span> minutes </span>
                    </div>
                    <Button href="/registration" className="btn-round button-main panel-btn-register" color="neutral" type="button">
                        REGISTER
                    </Button> 
                </div>
                </div>}
                <CookieConsent containerClasses="cookies-fibs" contentClasses="cookies-fibs-content" disableStyles={true}>This website uses cookies to enhance the user experience.</CookieConsent>
        </>
    );
}

export default PanelCountdown;
