import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";

import { useForm, Controller } from "react-hook-form";
// core components
import Navbar from "components/Navbars/Navbar.js";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import app from "../../utils/firebase";
import { ref, set, update, push, child } from "firebase/database";

import moment from "moment-timezone";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import codes from "country-calling-code";

import UploadDp from "./ActionStorage";
import axios from "axios";
import UploadImage from "./uploadImage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {/* <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box> */}
          <div className="index">{children}</div>
        </>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Registration() {
  const db = app();

  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const validation = Yup.object().shape({
    chooseCb1: Yup.bool().oneOf([true], "This is a required field"),
    chooseCb2: Yup.bool().oneOf([true], "This is a required field"),
    chooseCb3: Yup.bool().oneOf([true], "This is a required field"),
    company_name: Yup.string().required("This is a required field"),
    company_website: Yup.string().required("This is a required field"),
    office_address: Yup.string().required("This is a required field"),
    city: Yup.string().required("This is a required field"),
    postal_code: Yup.string().required("This is a required field"),
    country: Yup.string().required("This is a required field"),
    department: Yup.string().required("This is a required field"),
    name_title: Yup.string().required("This is a required field"),
    firstname: Yup.string().required("This is a required field"),
    middlename: Yup.string().required("This is a required field"),
    lastname: Yup.string().required("This is a required field"),
    job_title: Yup.string().required("This is a required field"),
    email: Yup.string().email().required("This is a required field"),
    tshirt: Yup.string().required("This is a required field"),
    mobile_number: Yup.string().required("This is a required field"),
    country_code: Yup.string().required("This is a required field"),
    dp: Yup.mixed().nullable().required("You need to provide a file"),
  });

  const optionsForm = {
    resolver: yupResolver(validation),
    defaultValues: {
      services: [],
      diet: [],
    },
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm(optionsForm);

  // const watchedValues = watch("sponsorship",);
  // console.log(watch("sponsorship"))

  const items = [
    { id: 1, label: "Platinum", value: 5999 },
    { id: 2, label: "Gold", value: 3999 },
    { id: 3, label: "Happy Hour", value: 2999 },
    { id: 4, label: "Golf", value: 2999 },
    { id: 5, label: "Coffee Break", value: 1999 },
  ];
  const [checkedSponsorItems, setCheckedSponsorItems] = useState({});
  const [totalSponsor, setTotalSponsor] = useState(0);

  const [newAgent, setNewAgent] = useState({});
  const [userId, setUserId] = useState("");
  const [showSentEmail, setShowSentEmail] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");

  const [file, setFile] = useState(); // progress
  const [preview, setPreview] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [tshirtSize, setTshirtSize] = useState(false);
  // const [openLoading, setOpenLoading] = useState(false)
  const [totalRegistration, setTotalRegistration] = useState(1200);

  const formattedTotalRegistration = totalRegistration.toFixed(2);

  // const [isCheckedSpouse, setIsCheckedSpouse] = useState(false);
  const isCheckedSpouse = watch("spouse", false); // default value is false

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    // console.log("objectUrl", objectUrl);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const onSubmit = async (data) => {
    const uid = new Date().getTime().toString();
    let date = moment().tz("Asia/Singapore").format("LLLL");

    // console.log(data);

    setNewAgent({ id: uid, ...data, created_at: date });

    set(ref(db, "registrations/" + uid), {
      ...data,
      diet: data.diet.length > 0 ? data.diet : "N/A",
      diet_allergy: data.diet_allergy ? data.diet_allergy : "N/A",
      diet_others: data.diet_others ? data.diet_others : "N/A",
      services: data.services.length > 0 ? data.services : "N/A",
      other_service: data.other_service ? data.other_service : "N/A",
      golf: data.golf ? data.golf : "N/A",
      spouse: data.spouse ? data.spouse : "N/A",
      id: uid,
      created_at: date,
    });

    UploadDp(`${firstname}-${lastname}`,file);

    setOpenDialog(true);

    Swal.fire({
      text: "Save successfully",
      target: "#custom-target",
      icon: "success",
      toast: true,
      timer: 5000,
      showConfirmButton: false,
      timerProgressBar: true,
      position: "bottom-right",
    });

    const autoMessageRegistered = `<div><p>
   Kindly check our attendees list page for newly registered attendees. </p> 
   <p>Name: ${data.firstname} ${data.lastname} <br/>
      Id: ${uid} <br/>
      Email: ${data.email} <br/>
      Company Name: ${data.company_name} <br/>
      Company Website: ${data.company_website}  
   </p>
   <p>Thank you!</p></div>`;

    var registerContent = {
      service_id: "service_razxbzp",
      template_id: "template_f496x19",
      user_id: "FiLUegZsUcqSyl78X",
      accessToken: "MvJXqbl4RPnK4i0_3-CAp",
      template_params: {
        to_name: "FIBS Customer Service",
        from_name: `${data.firstname} ${data.lastname}`,
        message:
          "Kindly check our attendees list page for newly registered attendees. ",
        from_company: data.company_name,
        from_website: data.company_website,
        from_uid: uid,
        from_email: data.email,
      },
    };

    await fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      body: JSON.stringify(registerContent),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log("Success:", response.status);
        console.log("Success, sent email to the CS")
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const newRegistered = {
      name: `${data.firstname} ${data.lastname}`,
      sendto: "customerservice@fibsglobalconference.com",
      // sendto: "flytechph@gmail.com",
      subject: `New Registration : ${data.firstname} ${data.lastname}`,
      message: autoMessageRegistered,
    };

    axios
      .post(`https://api.fibsglobalconference.com/v2`, null, {
        params: newRegistered,
      })
      .then((response) => {
        // console.log(response.data);
        console.log("Success")
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onSkipRedirect = () => {
    setOpenDialog(false);
    setShowSentEmail(false);

    Swal.fire({
      title: "Redirect to Zepoga Face2Face",
      // timer: 5000,
      backdrop: false,
      showCancelButton: true,
      showConfirmButton: true,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then(function () {
      window.location.href = `http://zepoga.com/register?firstname=${newAgent.firstname}&lastname=${newAgent.lastname}&email=${newAgent.email}`;
    });
  };

  const [value, setValue] = React.useState(0);
  const [showNextButton, setShowNextButton] = useState(true);
  const [showPackage1, setShowPackage1] = useState(true);
  const [showPackage2, setShowPackage2] = useState(true);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const paymentMethod = (payment) => {
    let date = moment().tz("Asia/Singapore").format("LLLL");

    const postData = {
      ...newAgent,
      payment,
      payment_confirm: false,
      updated_at: date,
    };

    update(ref(db, "registrations/" + newAgent.id), postData);

    if (payment === "Paypal") {
      document.getElementById("formPaypal").submit();
    } else {
      setShowSentEmail(true);
    }
  };

  const handleClose = () => {
    reset();
    setFile("");
    setOpenDialog(false);
  };

  const handleChange = (e, newValue) => {
    // if (e.target.files[0]) setFile(e.target.files[0]);
    setValue(newValue);
    setFile(e.target.files[0]);
    // setFile(e.target.files[0]);
    e.preventDefault();
  };

  const notShowNextButton = () => {
    setShowNextButton(false);
    setShowPackage2(true);
  };

  // const handleCheckboxChange = (event) => {
  //   isCheckedSpouse(event.target.checked);
  // };

  return (
    <>
      <Navbar />
      <SubHeader
        title="REGISTRATION"
        image={require("assets/img/hoi-an-night-market.jpg")}
      />

      <div className="wrapper position-relative ">
        <div className="container main mt-lg-5 px-lg-5">
          <h4 className="text-justify">
            Join the FIBS Global Conference with other like-minded peers and
            network members, with a focus on developing strong business growth
            and building long-lasting relationships. Meetings are optimized to
            provide a great network development platform.
          </h4>
          <div className="row pb-4 mt-5">
            <div className="col-12" align="center">
              <h1>Online Registration</h1>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {showPackage2 ? (
              <section className="" style={{ marginBottom: "200px" }}>
                <div
                  className="row pt-4 my-5 registration-box"
                  style={{
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    padding: "15px",
                    borderRadius: "15px",
                  }}
                >
                  <div className="form-group col-md-12">
                    <h4 className="mt-2" align="left">
                      <strong>Personal Information</strong>
                    </h4>
                  </div>

                  <div className="form-row col-lg-12" align="left">
                    <div className="col-lg-3 col-md-12 col-sm-12">
                      {/* <UploadImage form={{...register("dp", { required: true })}}/> */}

                      <div
                        style={{
                          width: "200px",
                          height: "200px",
                          border: "1px solid black",
                          textAlign: "center",
                          display: "flex",
                          padding: "3rem",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        {!file ? "Please upload your profile picture" : ""}

                        {file && (
                          <img
                            id="image"
                            style={{ width: "200px" }}
                            src={preview}
                          />
                        )}
                      </div>

                      <input
                        type="file"
                        accept="/image/*"
                        id="dp"
                        {...register("dp", {
                          required: true,
                          onChange: (e) => {
                            setFile(e.target.files[0]);
                          },
                        })}
                      />

                      <p className="h6 font-weight-bold text-danger mt-2">
                        {errors.dp?.message}
                      </p>
                    </div>
                    <div className="row col-lg-9 col-md-12 col-sm-12">
                      <div className="form-group col-md-2 col-sm-12">
                        <label htmlFor="title">Title</label>
                        <select
                          className="form-control form-select"
                          id="title"
                          {...register("name_title", { required: true })}
                        >
                          <option value="">Title</option>
                          <option value="Mr.">Mr.</option>
                          <option value="Mr.">Mrs.</option>
                          <option value="Mrs.">Ms.</option>
                          <option value="Mr.">Dr.</option>
                        </select>
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.name_title?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-4 col-sm-12">
                        <label htmlFor="firstname">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstname"
                          placeholder="First Name"
                          name="firstname"
                          {...register("firstname", {
                            required: true,
                            onChange: (e) => {
                              setFirstname(e.target.value);
                            },
                          })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.firstname?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-3 col-sm-12">
                        <label htmlFor="lastname">Middle Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="middlename"
                          placeholder="Middle Name"
                          name="middlename"
                          {...register("middlename", {
                            onChange: (e) => {
                              setLastname(e.target.value);
                            },
                          })}
                        />
                      </div>
                      <div className="form-group col-md-3 col-sm-12">
                        <label htmlFor="lastname">Family (Last) Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastname"
                          placeholder="Family (Last) Name"
                          name="lastname"
                          {...register("lastname", {
                            required: true,
                            onChange: (e) => {
                              setLastname(e.target.value);
                            },
                          })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.lastname?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="lastname">Job Title</label>
                        <input
                          type="text"
                          className="form-control"
                          id="job_title"
                          placeholder="Job Title "
                          {...register("job_title", { required: true })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.job_title?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="city">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          {...register("email", { required: true })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.email?.message}
                        </p>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="tshirt">T-Shirt Size</label>
                        <a
                          href="#"
                          className="btn btn-link text-primary m-0 p-0 pl-1"
                          onClick={() => setTshirtSize(true)}
                        >
                          Chart
                          <ArrowForwardIosIcon
                            sx={{ fontSize: 11, marginBottom: "1px" }}
                          />
                        </a>
                        <select
                          className="form-control form-select"
                          id="tshirt"
                          {...register("tshirt", { required: true })}
                        >
                          <option value="">Please select</option>
                          <option value="S">S</option>
                          <option value="M">M</option>
                          <option value="L">L</option>
                          <option value="XL">XL</option>
                          <option value="XXL">XXL</option>
                          <option value="XXXL">XXXL</option>
                        </select>
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.tshirt?.message}
                        </p>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="country_code">Country code</label>
                        <select
                          className="form-control form-select"
                          id="country_code"
                          {...register("country_code", { required: true })}
                        >
                          <option value="">Please select</option>
                          {codes.map((code) => (
                            <option>
                              {code.country} (+{code.countryCodes})
                            </option>
                          ))}
                        </select>
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.country_code?.message}
                        </p>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="city">Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobile_number"
                          placeholder="Mobile Number"
                          {...register("mobile_number", { required: true })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.mobile_number?.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-12" id="pending" align="center">
                                    <a type='button' href='#pending' className="btn btn-danger" onClick={() => showPackageContent2}> Submit</a>&nbsp;
                                </div>

                                <hr /> */}
                </div>
                <>
                  <div
                    className="row pt-4 my-5 registration-box"
                    style={{
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                      padding: "15px",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="form-group col-md-12">
                      <h4 className="mt-2" align="left">
                        <strong>Company Information</strong>
                      </h4>
                    </div>

                    <div className="form-row col-lg-12">
                      <div className="form-group col-md-6">
                        <label htmlFor="lastname">Company Name: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company_name"
                          placeholder="Company name"
                          {...register("company_name", {
                            required: "please enter Company name",
                          })}
                          error={!!errors.company_name}
                        />

                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.company_name?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="lastname">Company Website: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company_website"
                          placeholder="Company website"
                          {...register("company_website", { required: true })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.company_website?.message}
                        </p>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="lastname">Office Address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="office_address"
                          placeholder="Office Address"
                          {...register("office_address", { required: true })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.office_address?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="lastname">City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          placeholder="City"
                          {...register("city", { required: true })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.city?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="postal_code"
                          placeholder="Postal code"
                          {...register("postal_code", { required: true })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.postal_code?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="city">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          id="country"
                          placeholder="Country"
                          {...register("country", { required: true })}
                        />
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.country?.message}
                        </p>
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="department">Department</label>
                        <select
                          className="form-control form-select"
                          id="department"
                          {...register("department", { required: true })}
                        >
                          <option value="">Please select</option>
                          <option>Management</option>
                          <option>Sales</option>
                          <option>Finance</option>
                          <option>Human Resources</option>
                          <option>Network Development</option>
                          <option>Marketing</option>
                          <option>Procurement</option>
                          <option>Tradelane Development</option>
                          <option>Administrative</option>
                          <option>Operations / Customer Service</option>
                          <option>Project Logistics</option>
                          <option>Contract Logistics</option>
                        </select>
                        <p className="h6 font-weight-bold text-danger mt-2">
                          {errors.department?.message}
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <p>What services do you offer?</p>

                        <div className="d-flex flex-column px-3">
                  <div className="col">
                    <input type="checkbox" id="air" className="form-check-input" value="air" {...register("services")}/> 
                    <label htmlFor="air" className="form-check-label"> Air </label>
                  </div>
                  <div className="col">
                    <input type="checkbox" id="trucking" className="form-check-input" value="trucking" {...register("services")}/> 
                    <label htmlFor="trucking" className="form-check-label"> Trucking </label>
                  </div>
                  <div className="col">
                    <input type="checkbox" id="sea" className="form-check-input" value="sea" {...register("services")}/> 
                    <label htmlFor="sea" className="form-check-label"> Sea </label>
                  </div>
                  <div className="col">
                    <input type="checkbox" id="rail" className="form-check-input" value="rail" {...register("services")}/> 
                    <label htmlFor="rail" className="form-check-label"> Rail </label>
                  </div>


                  <div className="col">
                    <input type="checkbox" id="warehouse" className="form-check-input" value="warehouse" {...register("services")}/> 
                    <label htmlFor="warehouse" className="form-check-label"> Warehouse </label>
                  </div>
                  <div className="col">
                    <input type="checkbox" id="contract" className="form-check-input" value="contract logistics" {...register("services")}/> 
                    <label htmlFor="contract" className="form-check-label"> Contract logistics  </label>
                  </div>
                  <div className="col">
                    <input type="checkbox" id="project " className="form-check-input" value="project logistics" {...register("services")}/> 
                    <label htmlFor="project" className="form-check-label"> Project logistics  </label>
                  </div>
                  <div className="col">
                    <input type="checkbox" id="eCommerce" className="form-check-input" value="eCommerce" {...register("services")}/> 
                    <label htmlFor="eCommerce" className="form-check-label"> ECommerce </label>
                  </div>
                  <div className="col">
                    <input type="checkbox" id="customs_clearance" className="form-check-input" value="customs clearance" {...register("services")}/> 
                    <label htmlFor="customs_clearance" className="form-check-label">Customs clearance </label>
                  </div>
            
                </div>
                        <div className="col-lg-6 my-3">
                          <label className="form-check-label">
                            Others (please specify)?
                          </label>
                          <input
                            type="text"
                            placeholder="Others"
                            name="other_service"
                            className="form-control col-md-6"
                            {...register("other_service")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row pt-4 my-5 registration-box"
                    style={{
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                      padding: "15px",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="form-group">
                      <h4 className="col-md-12 mt-2" align="left">
                        <strong>Dietary Restrictions:</strong>
                      </h4>
                    </div>

                    <div className="form-row col-lg-12">
                      <div className="col-12 pt-2">
                        <div className="custom-control custom-checkbox custom-control-inline mr-4">
                          <input
                            type="checkbox"
                            id="vegetarian-0"
                            className="custom-control-input"
                            value="Vegetarian"
                            {...register("diet")}
                          />
                          <label
                            htmlFor="vegetarian-0"
                            className="custom-control-label"
                          >
                            Vegetarian
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline mr-4">
                          <input
                            type="checkbox"
                            id="vegetarian-1"
                            className="custom-control-input"
                            value="Vegan"
                            {...register("diet")}
                          />
                          <label
                            htmlFor="vegetarian-1"
                            className="custom-control-label"
                          >
                            Vegan
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline mr-4">
                          <input
                            type="checkbox"
                            id="vegetarian-2"
                            className="custom-control-input"
                            value="Halal"
                            {...register("diet")}
                          />
                          <label
                            htmlFor="vegetarian-2"
                            className="custom-control-label"
                          >
                            Halal
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline mr-4">
                          <input
                            type="checkbox"
                            id="vegetarian-3"
                            className="custom-control-input"
                            value="Kosher"
                            {...register("diet")}
                          />
                          <label
                            htmlFor="vegetarian-3"
                            className="custom-control-label"
                          >
                            Kosher
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline mr-4">
                          <input
                            type="checkbox"
                            id="vegetarian-4"
                            className="custom-control-input"
                            value="Gluten-free"
                            {...register("diet")}
                          />
                          <label
                            htmlFor="vegetarian-4"
                            className="custom-control-label"
                          >
                            Gluten-free
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline mr-4">
                          <input
                            type="checkbox"
                            id="vegetarian-5"
                            className="custom-control-input"
                            value="Lactose Intolerant"
                            {...register("diet")}
                          />
                          <label
                            htmlFor="vegetarian-5"
                            className="custom-control-label"
                          >
                            Lactose Intolerant
                          </label>
                        </div>
                      </div>
                      <div className="col-12 py-4">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Others</label>
                              <input
                                type="text"
                                placeholder="Others"
                                className="form-control"
                                {...register("diet_others")}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Food Allergy</label>
                              <input
                                type="text"
                                placeholder="Food Allergy"
                                className="form-control"
                                {...register("diet_allergy")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <hr/> */}

                  <div
                    className="row my-5 registration-box"
                    style={{
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                      padding: "15px",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="col-12">
                      {/* <div className="form-group">
                        <p className="col-md-12 mt-2" align="left">
                          Sponsorhip Packages
                        </p>
                        <label className="px-3">
                          Community connections improve with increased
                          investment in your branding. <br /> Choose one of the
                          sponsorship options below to take advantage of the
                          opportunity to gain greater exposure at the FIBS
                          Global Conference
                        </label>
                      </div> */}

                      {/* <div className="d-flex flex-column px-4">
                        <div className="col">
                          <input
                            type="checkbox"
                            id="platinumsponsor"
                            className="form-check-input"
                            value="platinum sponsor"
                            {...register("sponsorship")}
                          />
                          <label
                            htmlFor="platinumsponsor"
                            className="form-check-label"
                          >
                            Platinum (USD 5,999)
                          </label>
                        </div>
                        <div className="col">
                          <input
                            type="checkbox"
                            id="goldsponsor"
                            className="form-check-input"
                            value="gold sponsor"
                            {...register("sponsorship")}
                          />
                          <label
                            htmlFor="goldsponsor"
                            className="form-check-label"
                          >
                            Gold (USD 3,999)
                          </label>
                        </div>
                        <div className="col">
                          <input
                            type="checkbox"
                            id="happyhoursponsor"
                            className="form-check-input"
                            value="happy hour sponsor"
                            {...register("sponsorship")}
                          />
                          <label
                            htmlFor="happyhoursponsor"
                            className="form-check-label"
                          >
                            Happy Hour (USD 2,999)
                          </label>
                        </div>
                        <div className="col">
                          <input
                            type="checkbox"
                            id="golfsponsor"
                            className="form-check-input"
                            value="golf sponsor"
                            {...register("sponsorship")}
                          />
                          <label
                            htmlFor="golfsponsor"
                            className="form-check-label"
                          >
                            Golf (USD 2,999)
                          </label>
                        </div>
                        <div className="col">
                          <input
                            type="checkbox"
                            id="coffeesponsor"
                            className="form-check-input"
                            value="coffee sponsor"
                            {...register("sponsorship")}
                          />
                          <label
                            htmlFor="coffeesponsor"
                            className="form-check-label"
                          >
                            Coffee Break (USD 1,999)
                          </label>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="d-flex flex-column px-4">
                      {items.map((item) => (
                        <div className="col" key={item.id}>
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.label}
                                  {...register("sponsorship")}
                                />
                                {item.label} (${item.value})
                              </label>
                          
                        </div>
                      ))}
                    </div>
                      <h3>Total: ${totalSponsor}</h3> */}

                    <div className="col-12 py-4">
                      <div className="form-group">
                        <p
                          className="col-md-12 mt-2 font-weight-bold"
                          align="left"
                        >
                          Interested by
                        </p>
                      </div>
                      <div className="row px-3">
                        <div className="col-lg-6 form-group">
                          <div className="custom-control custom-checkbox custom-control-inline mr-4">
                            <input
                              type="checkbox"
                              id="golf"
                              className="custom-control-input"
                              value="FIBS Golf Game"
                              {...register("golf")}
                            />
                            <label
                              htmlFor="golf"
                              className="custom-control-label d-flex align-items-center"
                            >
                              Join FIBS Golf Game <br /> - Wait for further
                              announcement about the FIBS Golf Game
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 form-group">
                          <div className="custom-control custom-checkbox custom-control-inline mr-4">
                            <input
                              type="checkbox"
                              id="spouse"
                              className="custom-control-input"
                              value="spouse"
                              {...register("spouse")}
                            />
                            <label
                              htmlFor="spouse"
                              className="custom-control-label d-flex align-items-center"
                            >
                              Register Spouse (USD 500)
                              <br /> - Invited to join for all dinners and
                              cocktail reception
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12" align="center">
                      <div className="total_div">
                        <h1>
                          Total: USD{" "}
                          <span className="total">
                            {!isCheckedSpouse ? "1,200.00" : "1,700.00"}
                          </span>
                        </h1>
                        <input
                          type="hidden"
                          name="getfee"
                          id="getfee"
                          className="total"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="my-5">
                    {/* <div className="col-12" align="center">
                                        <div className="total_div">
                                            <h2>Total: USD <span className="total">1,350</span></h2>
                                            <input type="hidden" name="getfee" id="getfee" className="total" value="" />
                                        </div>
                                    </div> */}

                    <div className="col-12 pb-4 pt-4" align="left">
                      <p className="mb-2">
                        <b>Liability Waiver</b>
                      </p>
                      <input
                        type="checkbox"
                        name="waiver"
                        id="waiver"
                        value="1"
                        {...register("chooseCb1")}
                        className={`form-check-input ${
                          errors.chooseCb1 ? "is-invalid" : ""
                        }`}
                      />

                      <label htmlFor="waiver" className="form-check-label">
                        I hereby agree that, upon registering for the FIBS
                        Global Conference meeting, I am taking full
                        responsibility for my actions, and I fully understand
                        that the event organiser and FIBS Logistics Team will
                        not be held responsible in any event of injury,
                        accident, personal loss, and/or illness during the
                        event.
                      </label>
                      <p className="h6 font-weight-bold text-danger mt-2">
                        {errors.chooseCb1?.message}
                      </p>
                    </div>

                    <div className="col-12 py-3" align="justify">
                      <p align="left">
                        <b>Terms and Conditions</b>
                      </p>
                      <input
                        type="checkbox"
                        name="termsA"
                        id="termsA"
                        value="1"
                        {...register("chooseCb2")}
                        className={`form-check-input ${
                          errors.chooseCb2 ? "is-invalid" : ""
                        }`}
                      />

                      <label htmlFor="termsA" className="form-check-label">
                        Any cancellation will be charged a USD 100 admin fee
                        plus relevant bank charges to be borne by the delegate.
                      </label>
                      <p className="h6 font-weight-bold text-danger mt-2">
                        {errors.chooseCb2?.message}
                      </p>
                    </div>

                    <div className="col-12 py-3" align="justify">
                      <input
                        type="checkbox"
                        name="termsB"
                        id="termsB"
                        value="1"
                        {...register("chooseCb3")}
                        className={`form-check-input ${
                          errors.chooseCb3 ? "is-invalid" : ""
                        }`}
                      />

                      <label htmlFor="termsB" className="form-check-label">
                        This is to confirm that we do not have any outstanding
                        payables or disputes with any of our forwarding partners
                        beyond 60 days. This is to confirm that we are not
                        listed on any blacklist neither in FDRS, nor US Sanction
                        list.
                      </label>
                      <p className="h6 font-weight-bold text-danger mt-2">
                        {errors.chooseCb3?.message}
                      </p>
                    </div>
                  </div>

                  <div className="col-12" id="pending" align="center">
                    {/* <a type='submit' className="btn btn-danger" name="submit"
                                        onClick={handleClickOpen}
                                    > Submit</a>&nbsp; */}
                    {/* <a type='button' value="Submit" className="btn btn-danger" name="submit"> Submit</a>&nbsp; */}

                    <input type="submit" className="btn btn-danger" />
                  </div>
                </>
              </section>
            ) : (
              ""
            )}
          </form>

        </div>

        {/* <Dialog open={openLoading}>
      <DialogContent>
          <div className='d-block text-center'>
                <img
                    alt="sending ..."
                    className="n-logo"
                    style={{width:'100px'}}
                    src={require("assets/img/loading.gif")}
                ></img>
                <p style={{margin:'0px 20px'}}>Please wait ...</p>
            </div>
      </DialogContent>
    </Dialog> */}

        <Dialog open={tshirtSize} onClose={() => setTshirtSize(false)}>
          <DialogTitle className="bg-danger text-white">
            T-Shirt Size
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setTshirtSize(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div>
              <img src={require("assets/img/tshirt-size.jpeg")} />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog open={openDialog}>
          <DialogTitle className="bg-danger text-white">
            Successfully saved!
          </DialogTitle>
          <DialogContent>
            <div className="col-12 text-center okay mt-4">
              <p>
                <strong>
                  {" "}
                  Your Registration is completed. <br /> See you in Da Nang,
                  Vietnam!
                </strong>
                <br />
                Please expect an email from us.
                <br />
                Thank you!
              </p>
            </div>

            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              id="formPaypal"
            >
              <input type="hidden" name="cmd" value="_cart" />
              <input type="hidden" name="upload" value="1" />
              <input type="hidden" name="business" value="zepogapl@gmail.com" />
              <input type="hidden" name="lc" value="SG" />
              <input type="hidden" name="item_name_1" value="ggg" />
              <input
                type="hidden"
                name="amount_1"
                value={!isCheckedSpouse ? "1200" : "1700"}
              />
              <input type="hidden" name="item_number" value="fgdg" />
              <input type="hidden" name="button_subtype" value="services" />
              <input type="hidden" name="no_note" value="0" />
              <input
                type="hidden"
                name="cn"
                value="Add special instructions to the seller:"
              />
              <input type="hidden" name="no_shipping" value="2" />
              <input type="hidden" name="currency_code" value="USD" />
              <input type="hidden" name="tax_rate" value="0.045" />
              <input type="hidden" name="charset" value="utf-8" />
              <input
                type="hidden"
                name="bn"
                value="PP-BuyNowBF:btn_buynowCC_LG_wCUP.gif:NonHosted"
              />
              <input type="hidden" name="hosted_button_id" value="221" />
            </form>

            <h4 className="mb-2">Payment method:</h4>

            <label className="form-check-label mb-2">
              <strong>Bank Transfer</strong>
            </label>
            <table>
              <tr>
                <td>Beneficiary Name:</td>
                <td>ZEPOGA PTE LTD </td>
              </tr>
              <tr>
                <td>Beneficiary Bank:</td>
                <td>UNITED OVERSEAS BANK LIMITED</td>
              </tr>
              <tr>
                <td>Beneficiary Bank Address:</td>
                <td>No. 80 Raffles Place, #1 UOB Plaza, Singapore 048624 </td>
              </tr>
              <tr>
                <td>Account Number:</td>
                <td>460-352-499-8</td>
              </tr>
              <tr>
                <td>SWIFT Code:</td>
                <td> UOVB SGSG or UOVB SGSG FIBS</td>
              </tr>
            </table>
            {/* <div className="d-flex">
              <div className="col-lg-3 col-md text-center py-2">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/1/16/Former_Visa_%28company%29_logo.svg"
                  alt=""
                  className="img-fluid btnPaypal"
                  onClick={() => paymentMethod("Bank Transfer")}
                />
              </div>
              <div className="col-lg-3 offset-2 offset-md-0 col-md text-center py-2">
                <img
                  src="https://thumbor.forbes.com/thumbor/1280x868/https%3A%2F%2Fblogs-images.forbes.com%2Fsteveolenski%2Ffiles%2F2016%2F07%2FMastercard_new_logo-1200x865.jpg"
                  alt=""
                  className="img-fluid btnPaypal"
                  onClick={() => paymentMethod("Bank Transfer")}
                />
              </div>
            </div> */}

            <label className="form-check-label my-2">
              <strong>Paypal</strong>
            </label>
            <div className="col-lg-4 col-md text-center py-2">
              <img
                onClick={() => paymentMethod("Paypal")}
                alt="..."
                className="n-logo btnPaypal"
                src={require("assets/img/paypal-logo-small.png")}
              ></img>
            </div>
            {showSentEmail ? (
              <div className="col-12 text-center okay mt-5">
                <p>
                  Please expect an email from us for the Bank Details. <br />{" "}
                  Thank you!
                </p>
              </div>
            ) : (
              ""
            )}
          </DialogContent>
          {showSentEmail ? (
            <DialogActions>
              {/* <Button onClick={onSkipRedirect}>Skip</Button> */}
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          ) : (
            ""
          )}
        </Dialog>
        <div id="custom-target"></div>
        {/* <PanelCountdown date="02/18/2025" /> */}
        <DarkFooter />
      </div>
    </>
  );
}

export default Registration;
