import storage from "../../utils/firebaseStorage";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import app from "../../utils/firebase";
// import { update } from "firebase/database";

const UploadDp = (name,file) =>{
    // const db = app();
    console.log(name,file)

    const storageRef = ref(storage, `/image/fibs/${name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);


    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
        // setPercent(percent);
        // console.log("uploaded")
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // console.log(url);
        });
      }
    );
}

export default UploadDp;