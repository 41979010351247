import React, { useState } from "react";

// core components
import Navbar from "components/Navbars/Navbar.js";
// import SubHeader from "components/Headers/SubHeaderSponsor";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";

function Sponsor() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    event.preventDefault();
  };

  return (
    <>
      <Navbar />
      <SubHeader
        title="Be A Sponsor"
        image={require("assets/img/shakehand4.jpg")}
      />
      <div className="wrapper position-relative">
        <div className="container main my-lg-5 px-lg-5">
          <section className="section gb nopadtop">
            <div className="container">
              <div className="boxed boxedp4">
                <div className="row">
                  <div className="col-md-12 section-title">
                    <h2 className="text-center text-uppercase">
                      Affordable Sponsorship Pricing Packages
                    </h2>
                  </div>
                </div>

                <div className="text-justify">
                  <h4>
                    <strong>Why be a Sponsor?</strong>
                  </h4>

                  <p>
                    FIBS is hosting this conference to enhance relations with
                    selected partners.
                  </p>
                  <p>
                    This is a different event from most regular network company
                    events, as we are not making money on our partner and
                    friends. As we have a limited budget, the sponsorships help
                    us offer a better event for everyone.
                  </p>

                  <p>
                    The purpose of having a FIBS conference is to create a
                    productive meeting platform and build quality relationships.
                    Our goal is to make you leave the event with a smile and to
                    make you feel that you really leverage your time well.
                  </p>
                  <p>
                    We are confident that you will build long-lasting business
                    development with existing and new partners and friends.
                  </p>
                  <p>
                    The benefits of FIBS sponsorship act as a springboard to
                    further boost your efforts to forge solid connections.
                    Depending on the level of exposure you want, we are open to
                    discussing other sponsorship options.
                  </p>
                </div>
                {/* <div className="row row bg-white px-2 pb-3 my-3">
                                    <div className='col-lg-12 col-md-12 d-flex flex-row justify-content-center mt-5'>

                                        <a href="#">
                                            <Card className='mt-2' sx={{ maxWidth: 345, minHeight: "240px" }}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Platinum  - USD $7,500
                                                    </Typography>
                                                    <ul>
                                                        <li>Speaker’s opportunity</li>
                                                        <li>2 free conference passes</li>
                                                        <li>VIP table and banner for F2F meetings</li>
                                                        <li>1 full-page colour ad in conference booklet</li>
                                                        <li>3 complimentary post event photo books </li>
                                                    </ul>
                                                </CardContent>
                                            </Card>
                                        </a>
                                        <a href="#">
                                            <Card className='mt-2 ml-3' sx={{ maxWidth: 345, minHeight: "240px" }}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Gold  - USD $5,000
                                                    </Typography>
                                                    <ul>
                                                        <li>1 free conference pass</li>
                                                        <li>VIP table and banner for F2F meetings</li>
                                                        <li>1 full-page colour ad in conference booklet</li>
                                                        <li>1 complimentary post event photo book</li>
                                                    </ul>
                                                </CardContent>
                                            </Card>
                                        </a>
                                    </div>

                                    <div className='col-lg-12 col-md-12 d-flex flex-row justify-content-center'>
                                        <a href="#">
                                            <Card className='mt-2' sx={{ maxWidth: 345, minHeight: "235px" }}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Silver  - USD $3,000
                                                    </Typography>
                                                    <ul>
                                                        <li>VIP table and banner for F2F meetings</li>
                                                        <li>1 half-page colour ad in conference booklet</li>
                                                        <li>1 complimentary post event photo book</li>
                                                    </ul>
                                                </CardContent>
                                            </Card>
                                        </a>
                                        <a href="#">
                                            <Card className='mt-2 ml-3' sx={{ maxWidth: 345, minHeight: "235px" }}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Activity specific sponsorship  USD $2,000
                                                    </Typography>
                                                    <ul>
                                                        <li>Banners in activity specific event</li>
                                                        <li>1 half-page colour ad in conference booklet</li>
                                                        <li>1 complimentary post event photo book</li>
                                                    </ul>
                                                </CardContent>
                                            </Card>
                                        </a>
                                    </div>
                                </div> */}

                <div className="row d-flex flex-row justify-content-center mt-5">
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="bg-white shadow sponsor-box">
                      <div className="text-center p-4">
                        <h1 className="mb-0">
                          <small
                            className="align-top text-muted font-weight-medium"
                            style={{ fontSize: "22px", lineHeight: "45px" }}
                          >
                            $
                          </small>
                          5,999
                        </h1>
                      </div>
                      <div className="bg-danger text-white text-center p-4">
                        <h3 className="m-0">Platinum</h3>
                      </div>
                      <div
                        className="d-flex flex-column px-3 py-4 "
                        style={{ maxHeight: "500px", minHeight: "500px" }}
                      >
                        <ul>
                          <li>
                            1 VIP Table (face-to-face meetings at your dedicated
                            table)
                          </li>
                          <li>
                            <strong>2 passes to the conference</strong>
                          </li>
                          <li>Toast at the cocktail party</li>
                          <li>Staplers with sponsor logo on every F2F table</li>
                          <li>
                            1 full-page print advertisement in the conference
                            booklet or directory
                          </li>
                          <li>
                            Company logo on conference website with a link to
                            company website
                          </li>
                          <li>
                            Stand-up banner with company logo at VIP table
                          </li>
                          <li>Company Logo on backdrop at opening ceremony </li>
                          <li>
                            Company logo to be shown at after-conference movie
                            or materials
                          </li>
                        </ul>
                      </div>
                      <div className="row justify-content-center pb-4">
                        <a
                          href="mailto:customerservice@zepoga.com"
                          className="btn btn-outline-danger py-2 px-4 my-2"
                        >
                          Email Us Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="bg-white shadow sponsor-box">
                      <div className="text-center p-4">
                        <h1 className="mb-0">
                          <small
                            className="align-top text-muted font-weight-medium"
                            style={{ fontSize: "22px", lineHeight: "45px" }}
                          >
                            $
                          </small>
                          3,999
                        </h1>
                      </div>
                      <div className="bg-danger text-white text-center p-4">
                        <h3 className="m-0">Gold</h3>
                      </div>
                      <div
                        className="d-flex flex-column px-3 py-4"
                        style={{ maxHeight: "500px", minHeight: "450px" }}
                      >
                        <ul>
                          <li>
                            1 VIP Table (face-to-face meetings at your dedicated
                            table)
                          </li>
                          <li>
                            <strong>2 passes to the conference</strong>
                          </li>
                          <li>Toast at the cocktail party</li>
                          <li>
                            1 full-page print advertisement in the conference
                            booklet or directory
                          </li>
                          <li>
                            Company logo on conference website with a link to
                            company website
                          </li>
                          <li>
                            Stand-up banner with company logo at VIP table
                          </li>
                          <li>Company Logo on backdrop at opening ceremony </li>
                          <li>
                            Company logo to be shown at after-conference movie
                            or materials
                          </li>
                        </ul>
                        <div
                          className="d-flex flex-wrap justify-content-center"
                          style={{ gap: "10px 50px" }}
                        >
                          <a
                            target="_blank"
                            href="https://www.googleadservices.com/pagead/aclk?sa=L&ai=DChcSEwi_997t0pL_AhWEV30KHRs5AqoYABACGgJzZg&ohost=www.google.com&cid=CAESbOD2Hyfi7x_e0IKM2yHEBGWSIx3Y7PeuizF0BqzavZ8fn-5YmOS0P6ZD44cpI1C0O9yd44EvxgElTe4hMWvHhHoY1GYPbsSvkwlZr80WcekkKuN4NAp-B5Uk4ddQXC-vznVPzswJw_Fw_nQ-XQ&sig=AOD64_09RxJznt32p7tWiMXA79ESOjiKlg&q&adurl&ved=2ahUKEwizytft0pL_AhXCwjgGHQ9-C2gQ0Qx6BAgKEAE"
                          >
                            <img
                              alt="QUOTISS"
                              src={require("assets/img/sponsor/QUOTISS.png")}
                              className="img-responsive"
                              width={110}
                            ></img>
                          </a>
                          <a target="_blank" href="https://www.atlogistics.co">
                            <img
                              alt="A&T International Logistics"
                              src={require("assets/img/sponsor/AT-international.png")}
                              className="img-responsive"
                              width={110}
                            ></img>
                          </a>
                          <a target="_blank" href="#">
                            <img
                              alt="GOL logo"
                              src={require("assets/img/sponsor/GOL.png")}
                              className="img-responsive"
                              width={110}
                            ></img>
                          </a>
                        </div>
                      </div>
                      <div className="row justify-content-center pb-4">
                        <a
                          href="mailto:customerservice@zepoga.com"
                          className="btn btn-outline-danger py-2 px-4 my-2"
                        >
                          Email Us Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="bg-white shadow sponsor-box">
                      <div className="text-center p-4">
                        <h1 className="mb-0">
                          <small
                            className="align-top text-muted font-weight-medium"
                            style={{ fontSize: "22px", lineHeight: "45px" }}
                          >
                            $
                          </small>
                          2,999
                        </h1>
                      </div>
                      <div className="bg-danger text-white text-center p-4">
                        <h3 className="m-0">Happy Hour </h3>
                      </div>
                      <div
                        className="d-flex flex-column px-3 py-4"
                        style={{ maxHeight: "500px", minHeight: "500px" }}
                      >
                        <ul>
                          <li>Small speech / toast to everyone </li>
                          <li>
                            <strong>1 pass to the conference</strong>
                          </li>
                          <li>
                            1 half-page print advertisement in the conference
                            booklet or directory
                          </li>
                          <li>
                            Company logo on conference website with a link to
                            company website
                          </li>
                          <li>Company Logo on backdrop at opening ceremony </li>
                          <li>
                            Company logo to be shown at after-conference movie
                            or materials
                          </li>
                        </ul>
                        <div className="d-flex justify-content-center mb-4">
                          <a target="_blank" href="https://www.shipco.com/">
                            <img
                              alt="ShipcoTransportLogo"
                              src={require("assets/img/sponsor/ShipcoTransportLogo.png")}
                              className="img-responsive mt-2"
                              width={90}
                            ></img>
                          </a>
                        </div>
                      </div>

                      <div className="row justify-content-center pb-4">
                        <a
                          href="mailto:customerservice@zepoga.com"
                          className="btn btn-outline-danger py-2 px-4 my-2"
                        >
                          Email Us Now
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-4 col-md-6 mb-5">
                                        <div className="bg-white shadow ">
                                            <div className="text-center p-4">
                                                <h1 className="mb-0"><small className="align-top text-muted font-weight-medium"
                                                    style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>1,750</h1>
                                                    <h3 className='mb-0'>Limitation of 10</h3>
                                            </div>
                                            <div className="bg-danger text-white text-center p-4">
                                                <h3 className="m-0">VIP Table </h3>
                                            </div>
                                            <div className="d-flex flex-column align-items-center text-center px-3 py-4">
                                                <p>Dedicated VIP Table (all one 2 one meetings at your dedicated table)</p>
                                            </div>
                                            <div className='row justify-content-center pb-4'>
                                                <a href="mailto:customerservice@zepoga.com" className="btn btn-outline-danger py-2 px-4 my-2">Email Us Now</a>
                                            </div>

                                        </div>
                                    </div> */}
                  {/* <div className="col-lg-4 col-md-6 mb-5">
                                        <div className="bg-white shadow ">
                                            <div className="text-center p-4">
                                                <h1 className="mb-0"><small className="align-top text-muted font-weight-medium"
                                                    style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>5,000</h1>
                                            </div>
                                            <div className="bg-danger text-white text-center p-4">
                                                <h3 className="m-0">Gold</h3>
                                            </div>
                                            <div className="d-flex flex-column align-items-center text-center px-3 py-4" style={{ maxHeight: "350px", minHeight: "350px" }}>
                                                <p>1 free conference passes</p>
                                                <p>VIP table and banner for F2F meetings</p>
                                                <p>1 full-page colour ad in conference booklet</p>
                                                <p>1 complimentary post event photo book </p>
                                            </div>
                                            <div className='row justify-content-center pb-4'>
                                                <a href="mailto:customerservice@zepoga.com" className="btn btn-outline-danger py-2 px-4 my-2">Email Us Now</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 mb-5">
                                        <div className="bg-white shadow ">
                                            <div className="text-center p-4">
                                                <h1 className="mb-0"><small className="align-top text-muted font-weight-medium"
                                                    style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>7,500</h1>
                                            </div>
                                            <div className="bg-danger text-white text-center p-4">
                                                <h3 className="m-0">Platinum</h3>
                                            </div>
                                            <div className="d-flex flex-column align-items-center text-center px-3 py-4" style={{ maxHeight: "350px", minHeight: "350px" }}>
                                                <p>Speaker’s opportunity</p>
                                                <p>2 free conference passes</p>
                                                <p>VIP table and banner for F2F meetings</p>
                                                <p>1 full-page colour ad in conference booklet</p>
                                                <p>3 complimentary post event photo books </p>

                                            </div>
                                            <div className='row justify-content-center pb-4'>
                                                <a href="mailto:customerservice@zepoga.com" className="btn btn-outline-danger py-2 px-4 my-2">Email Us Now</a>
                                            </div>

                                        </div>
                                    </div> */}
                </div>

                <div className="row d-flex flex-row justify-content-center">
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="bg-white shadow sponsor-box">
                      <div className="text-center p-4">
                        <h1 className="mb-0">
                          <small
                            className="align-top text-muted font-weight-medium"
                            style={{ fontSize: "22px", lineHeight: "45px" }}
                          >
                            $
                          </small>
                          4,499
                        </h1>
                      </div>
                      <div className="bg-danger text-white text-center p-4">
                        <h3 className="m-0">Golf </h3>
                      </div>
                      <div
                        className="d-flex flex-column px-3 py-4"
                        style={{ maxHeight: "300px", minHeight: "400px" }}
                      >
                        <ul>
                          <li>Logo on all golf related market materials</li>
                          <li>
                            <strong>1 pass to the conference</strong>
                          </li>
                          <li>Popup stands at tee off</li>
                          <li>Golf price presented as sponsor of the event</li>
                          <li>Cap with logo</li>
                          <li>
                            1 full-page print advertisement in the conference
                            booklet or directory
                          </li>
                          <li>
                            Company logo on conference website with a link to
                            company website
                          </li>
                          <li>
                            {" "}
                            Company logo to be shown at after-conference movie
                            or materials
                          </li>
                        </ul>
                      </div>
                      <div className="row justify-content-center pb-4">
                        <a
                          href="mailto:customerservice@zepoga.com"
                          className="btn btn-outline-danger py-2 px-4 my-2"
                        >
                          Email Us Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div className="bg-white shadow sponsor-box">
                      <div className="text-center p-4">
                        <h1 className="mb-0">
                          <small
                            className="align-top text-muted font-weight-medium"
                            style={{ fontSize: "22px", lineHeight: "45px" }}
                          >
                            $
                          </small>
                          1,999
                        </h1>
                      </div>
                      <div className="bg-danger text-white text-center p-4">
                        <h3 className="m-0">Coffee Break </h3>
                      </div>
                      <div
                        className="d-flex flex-column px-3 py-4"
                        style={{ maxHeight: "304px", minHeight: "347px" }}
                      >
                        <ul>
                          <li>
                            <strong>1 pass to the conference</strong>
                          </li>
                          <li>
                            1 half-page print advertisement in the conference
                            booklet or directory.
                          </li>
                          <li>
                            Company logo on conference website with a link to
                            company website
                          </li>
                          <li>Company Logo on backdrop at opening ceremony </li>
                          <li>
                            Logo printing on 2 banners at coffee station(s) each
                            day
                          </li>
                          <li>
                            Company logo to be shown at after-conference movie
                            or materials
                          </li>
                        </ul>
                      </div>
                      <div className="d-flex justify-content-center">
                        <a target="_blank" href="https://stlojistik.com.tr/en/">
                          <img
                            alt="ST GLOBAL FORWARDING & LOGISTIC SERVICES CO."
                            src={require("assets/img/sponsor/st.png")}
                            className="img-responsive"
                            width={150}
                          ></img>
                        </a>
                      </div>
                      <div className="row justify-content-center pb-4">
                        <a
                          href="mailto:customerservice@zepoga.com"
                          className="btn btn-outline-danger py-2 px-4 my-2"
                        >
                          Email Us Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <div className="container">
          <div class="container1 row ">
            <div class="box12 col-lg-4 bg-white shadow sponsor-box">
              <div className="text-center p-4">
                <h1 className="mb-0">
                  <small
                    className="align-top text-muted font-weight-medium"
                    style={{ fontSize: "22px", lineHeight: "45px" }}
                  >
                    $
                  </small>
                  1,999
                </h1>
              </div>
              <div className="bg-danger text-white text-center p-4">
                <h3 className="m-0">Coffee Break </h3>
              </div>
              <div className="d-flex flex-column px-3 py-4">
                <ul>
                  <li>
                    1 VIP Table (all one 2 one meetings at your dedicated table)
                  </li>
                  <li>
                    <strong>2 passes to the conference</strong>
                  </li>
                  <li>Toast at the cocktail party</li>
                  <li>Staplers with sponsor logo on every F2F table</li>
                  <li>
                    1 full-page print advertisement in the conference booklet /
                    directory
                  </li>
                  <li>
                    Company logo on conference website with a link to company
                    website
                  </li>
                  <li>Stand-up banner with company logo at VIP table</li>
                  <li>Company Logo on backdrop at opening ceremony </li>
                  <li>
                    Company logo to be shown at after-conference movie /
                    materials{" "}
                  </li>
                </ul>
              </div>
              <div className="row justify-content-center pb-4 card-bottom">
                <a
                  href="mailto:customerservice@zepoga.com"
                  className="btn btn-outline-danger py-2 px-4 my-2"
                >
                  Email Us Now
                </a>
              </div>
            </div>
            <div class="box12 col-lg-4 bg-white shadow sponsor-box">
              <div className="text-center p-4">
                <h1 className="mb-0">
                  <small
                    className="align-top text-muted font-weight-medium"
                    style={{ fontSize: "22px", lineHeight: "45px" }}
                  >
                    $
                  </small>
                  1,999
                </h1>
              </div>
              <div className="bg-danger text-white text-center p-4">
                <h3 className="m-0">Coffee Break </h3>
              </div>
              <div className="d-flex flex-column px-3 py-4">
                <ul>
                  <li>Logo on all golf related market materials</li>
                  <li>Popup stands at tee off</li>
                  <li>Golf price presented as sponsor of the event</li>
                  <li>Cap with logo</li>
                </ul>
              </div>
              <div className="row justify-content-center pb-4 card-bottom">
                <a
                  href="mailto:customerservice@zepoga.com"
                  className="btn btn-outline-danger py-2 px-4 my-2"
                >
                  Email Us Now
                </a>
              </div>
            </div>
            
            <div class="card box12 col-lg-4 bg-white shadow sponsor-box">
           
                <div class="card-header text-center">
                  <h1 className="mb-0">
                    <small
                      className="align-top text-muted font-weight-medium"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    1,999
                  </h1>

                  <div className="bg-danger text-white text-center p-4">
                    <h3 className="m-0">Coffee Break </h3>
                  </div>
                </div>
                <div class="card-body px-3 py-4">
                  <ul>
                    <li>
                      <strong>1 passes to the conference</strong>
                    </li>
                    <li>
                      1 half-page print advertisement in the conference booklet
                      / directory.
                    </li>
                    <li>
                      Company logo on conference website with a link to company
                      website
                    </li>
                    <li>Company Logo on backdrop at opening ceremony </li>
                    <li>
                      Logo printing on 2 banners at coffee station (s) each day
                    </li>
                    <li>
                      Company logo to be shown at after-conference movie
                      /materials{" "}
                    </li>
                  </ul>
                </div>
                <div class="card-footer text-muted">
                  <a
                    href="mailto:customerservice@zepoga.com"
                    className="btn btn-outline-danger py-2 px-4 my-2"
                  >
                    Email Us Now
                  </a>
                </div>
            </div>
          </div>
        </div> */}

        <PanelCountdown date="02/18/2025" />
        {/* <DarkFooter /> */}
      </div>
    </>
  );
}

export default Sponsor;
