import React from "react";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}?&autoplay=1&mute=1`}
      frameBorder="0"
      allow="accelerometer; autoplay"
      allowFullScreen
      title="FIBS"
    />
  </div>
);

export default YoutubeEmbed;
