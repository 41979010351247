/*eslint-disable*/
import React from "react";
import {
  Button,
  Row,
  Col,
  Container
} from "reactstrap";
import './style.scss';
// core components

function SubHeaderBanner(props) {

  return (
    <>
      <section className="bs-section inner-banner overlay community-section-pageHeader ember-view">
        <svg className="position-absolute start-0 bottom-0 text-white flip-y" width="100%" height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 152" fill="none" preserveAspectRatio="none">
          <path d="M126.597 138.74C99.8867 127.36 76.6479 109.164 59.2161 85.9798L0 3.05176e-05L1440 0C1440 0 1419.98 25.8404 1380.15 32.9584L211.382 150.811C182.549 154.283 153.308 150.12 126.597 138.74Z" fill="currentColor"></path>
        </svg>
        <div
          className="background-layer bg-cover bg-center-bottom"
          style={{
            backgroundImage: "url(" + props.image + ")",
          }}
        ></div>
      
        <Container>
          <div className="content-center brand">
            <h1 className="sub-header-title"><span>{props.title}</span></h1>
          </div>
        </Container>
      </section>
    </>
  );
}

export default SubHeaderBanner;
