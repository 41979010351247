/*eslint-disable*/
import React from "react";
import {
  Button,
  Row,
  Col,
  Container
} from "reactstrap";
// core components
import './style.scss';

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-blur">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/header.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand">
            <img
              alt="..."
              className="n-logo"
              src={require("assets/img/fibs_gLobal_white.png")}
            ></img>
            <h1 className="h1-seo">February 18 - 21, 2025</h1>
            <h1 className="h2-seo">Da Nang, Vietnam</h1>
            {/* <h3>A beautiful Bootstrap 4 UI kit. Yours free.</h3>
            <Row>
            <Col md="12">
              <Button color="danger" size="lg">Register</Button>
              <Button color="white" size="lg">
                Inquire
              </Button>
            </Col>
          </Row> */}
          </div>
        </Container>
      </div>
{/* 
      <section className="main-banner" id="top" data-section="section1">
        <video autoPlay muted loop id="bg-video">
          <source src={require("assets/img/FIBS_Corporate_Video.mp4")} type="video/mp4" />
        </video>

        <div className="video-overlay header-text">
          <div className="caption content-center brand">
            <img
              alt="..."
              className="n-logo"
              
              src={require("assets/img/fibs_gLobal_white.png")}
            ></img>
            <h1 className="h1-seo">September 13 - 16, 2023</h1>
            <h2><em>Your</em> Classroom</h2>
            <div className="main-button">
              <div className="scroll-to-section"><a href="#section2">Discover more</a></div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default IndexHeader;
