import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function CompleteExamples() {
  return (
    <>
      <div className="">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">Pre-schedule meetings easily with our Scheduler</h2>
              <p className="description">
                We provide delegates access to Face-to-Face scheduler to manage and book their meetings in advance for the upcoming conference.
                <br />
                Registered delegates will be given login details to access to their account 1 month before the event.  They can update their profiles and schedule meetings slots in the platform.
                <br />
                Our customer service team is ready to help should you encounter any issues
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CompleteExamples;
