import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue} from "firebase/database";
import app from "../../utils/firebase";
import CsvDownloadButton from 'react-json-to-csv'
import exportFromJSON from 'export-from-json'
import { Link } from 'react-router-dom';
import './style.scss';


function List(props){
    const db = app();

    const [registerd,setRegisterd] = useState([]);
    const [isLogin, setIsLogin] = useState(false)
    const [filterByYear, setFilterByYear] = useState([])
    const [year, setYear] = useState('2024');

    const headers = [ "#", "Company Name","Company Website","Office Address", 
    "City", "Postal Code", "Country", "Department", "Services", "Title", "First Name", "Last Name", "Job Title", "Email", "T-Shirt size", "Mobile Number","Country Code", "Diet", "Food Allergy" ]


    const getYearFromDate = (dateString) => {
        const date = new Date(dateString);
        return date.getFullYear();
      };

   

    useEffect(() => {
        var storedName = localStorage.getItem('name');
        var storedPw = localStorage.getItem('pw');

        if (storedName === "customerservice@fibsglobalconference.com" && storedPw === "fibs2023@!%"){
            const starCountRef = ref(db, 'registrations');
            onValue(starCountRef, (snapshot) => {
                let records = [];

                snapshot.forEach(element => {
                    // var data = element.data();
                    let keyName = element.key;
                    let data = element.val()
                    records.push({"key": keyName, "data": data})
                });
                
                // console.log("records",records)
                // console.log("filteredData",filteredData)

                setRegisterd(records)
                // console.log('querySnapshot',records)
    
            });
        }else{
            props.history.push('/v2/fibs/login')
        }


    },[])


    useEffect(() => {
      
          const filtered = registerd.filter((item) => getYearFromDate(item.data.created_at) === 2024 || getYearFromDate(item.data.created_at) === 2025);
          setFilterByYear(filtered)
     
      }, [year, registerd]);
    

    const download = function (data) {
 
        // Creating a Blob for having a csv file format
        // and passing the data with type
        const blob = new Blob([data], { type: 'text/csv' });
     
        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob)
     
        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a')
     
        // Passing the blob downloading url
        a.setAttribute('href', url)
     
        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('download', 'download.csv');
     
        // Performing a download with click
        a.click()
    }
    const csvmaker = function (data) {
 
        // Empty array for storing the values
        
     
        // Headers is basically a keys of an
        // object which is id, name, and
        // profession

        let csvRows = [];

        const headers = Object.keys(data[0].data);
        csvRows.push(headers);
    
        data && data.map((row,index) => {
            
            const values = Object.values(row.data);
            csvRows.push(values)

            // console.log(csvRows)


        })

        return csvRows.join('\n')
        
    }
     
    const dlList = (registerd) => {

        let fullData = []
        const csvdata = csvmaker(registerd);
        download(csvdata);
    }

    // const download = (data, fileName) => {
    //     const blob = new Blob([data], { type: 'text/csv' });
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.setAttribute('hidden', '');
    //     a.setAttribute('href', url);
    //     a.setAttribute('download', fileName + '.csv');
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    // };

    // const download = () => {
    //     var pom = document.createElement('a');
    //     var csvContent=registerd; //here we load our csv data 
    //     var blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
    //     var url = URL.createObjectURL(blob);
    //     pom.href = url;
    //     pom.setAttribute('download', 'foo.csv');

    //     window.open(pom);
    // }
    return (
        <div className="container-fluid">
            <h2 className="mt-3">Fibs List of Attendees</h2>
            
            <button onClick={()=>dlList(registerd)} className="btn btn-primary">Download file via csv</button>

            <Link to="/v2/fibs/hotel-list">
                <button className="btn btn-danger">Go to Hotel Reservation</button>
            </Link>
            
            {/*
            <CsvDownloadButton 
                data={registerd} 
                filename="registered-list"
                headers={headers}
                /> */}
            <table className="table table-striped table-bordered d-block" style={{height:"700px",overflow:"auto"}}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Title</th>
                    <th>First Name</th>
                    <th>Middle Name</th>
                    <th>Last Name</th>
                    <th>Job Title</th>
                    <th>Company Website</th>
                    <th>Office address</th>
                    <th>City</th>
                    <th>Postal Code</th>
                    <th>Country</th>
                    <th>Department</th>
                    <th>Services</th>
                    <th>Other Services</th>
                    
                    <th>T-shirt size</th>
                    <th>Mobile Number</th>
                    <th>Country Code</th>
                    <th>Golf</th>
                    <th>Spouse</th>
                    <th>Diet</th>
                    <th>Other Diet</th>
                    <th>Food Allergy</th>
                    <th>Photo Attendees</th>
                    <th>Payment made by</th>
                    <th>Payment status</th>
                    <th>Created at</th>
                </tr>
            </thead>
            <tbody>
                {filterByYear.map((row,index) => {
                    return(
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row.data.id}</td>
                            <td>{row.data.company_name}</td>
                            <td>{row.data.email}</td>
                            <td>{row.data.name_title}</td>
                            <td>{row.data.firstname}</td>
                            <td>{row.data.middlename}</td>
                            <td>{row.data.lastname}</td>
                            <td>{row.data.job_title}</td>
                            <td>{row.data.company_website}</td>
                            <td>{row.data.office_address}</td>
                            <td>{row.data.city}</td>
                            <td>{row.data.postal_code}</td>
                            <td>{row.data.country}</td>
                            <td>{row.data.department}</td>
                            <td>{row.data.services !== "N/A" ? row.data.services && (row.data.services).join(", ") : row.data.services}</td>
                            <td>{row.data.other_service}</td>
                            <td>{row.data.tshirt}</td>
                            <td>{row.data.mobile_number}</td>
                            <td>{row.data.country_code}</td>
                            <td>{row.data.golf}</td>
                            <td>{row.data.spouse}</td>
                            <td>{row.data.diet !== "N/A" ? row.data.diet && (row.data.diet).join(", ") : row.data.diet}</td>
                            <td>{row.data.diet_others}</td>
                            <td>{row.data.diet_allergy}</td>
                            <td>
                                <img
                                    alt="no photo"
                                    className="n-logo"
                                    style={{width:'350px'}}
                                    src={`https://firebasestorage.googleapis.com/v0/b/fibsevent.appspot.com/o/image%2Ffibs%2F${row.data.firstname}-${row.data.lastname}?alt=media&token=6dadcab5-228c-4386-9795-1f978ada0d8f`}
                                ></img>
                                <a className="mt-3" href={`https://firebasestorage.googleapis.com/v0/b/fibsevent.appspot.com/o/image%2Ffibs%2F${row.data.firstname}-${row.data.lastname}?alt=media&token=6dadcab5-228c-4386-9795-1f978ada0d8f`}>
                                    Link
                                </a>
                            </td>
                            <th>{row.data.payment}</th>
                            <th>{(row.data.payment_confirm?"true":"false")}</th>
                            <td>{row.data.created_at}</td>
                        </tr>
                    )
                })}


            </tbody>
            </table>
        </div>
    )
}


export default List;