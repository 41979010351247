import React from "react"
import './style.scss';

export default function (props) {


    function checkLogin(){

    
        var userName = document.getElementById('userName');
        var userPw = document.getElementById('userPw');

        if (userName.value === "customerservice@fibsglobalconference.com" && userPw.value === "fibs2023@!%"){

            localStorage.setItem('name', userName.value);
            localStorage.setItem('pw', userPw.value);
            
            props.history.push('/v2/fibs/list')

        }else{
            alert('Error on login');
        }
    
    }


  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              id="userName"
              className="form-control mt-1"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              id="userPw"
              className="form-control mt-1"
              placeholder="Enter password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button className="btn btn-primary" onClick={checkLogin}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}