import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function NucleoIcons() {
  return (
    <>
      <div className="section section-nucleo-icons py-5 my-5">
        <Container>
          <Row>
            <Col lg="4" md="12" className="hidden-sm hidden-xs">
              <div className="custom-module">
                <div className="d-lg-block d-none text-center mt-3">
                  <img src={require("assets/img/FN.jpg")} className="img-responsive mb-3" alt="founder" />
                  <strong>Fredrik Nyberg</strong><br />Network Director
                </div>
                <div className="d-lg-none text-center">
                  {/* <img src="images/Directors.png" className="img-responsive mb-3"
                    style="border: 2px solid #f47920;" alt="founder" /> */}
                  {/* <img src={require("assets/img/Fredrik.png")} className="img-responsive" alt="founder" style={{ width: "200px" }} />
                  <strong>Fredrik Nyberg</strong><br />Network Director */}

                  <div className="col-md-4 hidden-sm hidden-xs m-auto">
                    <div className="custom-module">
                      <div className="text-center w-100">
                        <img src={require("assets/img/FN.jpg")} alt="Fredrik" className="d-block m-auto director-image pb-4" style={{ width: "200px" }} />
                        <br/><strong>Fredrik Nyberg</strong><br/>Network Director
                          </div>
                      </div>
                    </div>
                  </div>

                </div>
                {/* <h2 className="title">Events</h2>
              <h5 className="description">
              The last 2.5 years have been crazy with the Covid pandemic, lockdowns, travel restrictions and disruptions in the supply chain.  <br/>
              Thankfully, we have online platforms which allows us to connect with each other virtually but nothing beats the face to face interactions and conversations sitting across each other. <br/>
              FIBS Logistics will be having our very own Global Conference this year from 13th to 16th September 2023 in Dubai.  We have come up with a programme that encompasses a good mixture of social engagement as well as Face-to-Face meetings over a course of 3.5 days.  The Face-to-Face meetings will form a dominant part of the conference, followed by network and updates from our internal and external speakers. <br/>
              The event will be held at The Westin Dubai Mina Seyahi Beach Resort & Marina, and it will be our honour to have you join us.  I’m sure we will lots to share and stronger bonds to be made when we meet to make up for the lost Covid years. <br/>
              Looking forward to seeing you all in Dubai.  Growing Together. Your Global Family.  
              </h5>
              <Button
                className="btn-round mr-1"
                color="info"
                href="/nucleo-icons"
                size="lg"
                target="_blank"
              >
                View Demo Icons
              </Button>
              <Button
                className="btn-round"
                color="info"
                href="https://nucleoapp.com/?ref=1712"
                outline
                size="lg"
                target="_blank"
              >
                View All Icons
              </Button> */}
            </Col>
            <Col lg="8" md="12">
              <div className="">
                {/* <h6 className="text-uppercase text-primary mb-3">Director's message</h6> */}
                <p className="font-weight-bold mt-3">Dear Friends and Partners, </p>
                <p>It was such a privilege to see many familiar faces during our last FIBS Global Conference in Dubai.</p>
                <p>It is with great pleasure and excitement that I welcome you all to Da Nang, Vietnam for our next FIBS Global Conference, where we come together to celebrate the power of connectivity and collaboration across borders, joined by an incredibly diverse group of individuals from all corners of the globe, representing a rich tapestry of cultures, ideas, and experiences. </p>
                
                <p>This event is not just about connecting with like-minded professionals; it's about harnessing the collective power of our global network to tackle some of the most pressing challenges facing our world today – the increasingly crazy world of logistics. </p>
                <p>Throughout the 3.5 days, let us engage more, and explore more opportunities for collaboration, and, most importantly, to build friendships that transcend borders.</p>
                <p>It is through understanding and embracing our diversity that we can pave the way for a brighter future, where global challenges are met with global solutions.</p>
                <p>I would like to express my sincere gratitude to each and every one of you for being part of this international network event.  </p>
                <p>Looking forward to seeing you all in Da Nang, Vietnam. </p>
                <p>Growing Together.  Your Global Family.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default NucleoIcons;
